import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AxiosResponse } from 'axios';
import cronstrue from 'cronstrue';
import { MsgboxService } from 'src/app/helpers/msgbox.service';
import { RbDataService } from 'src/app/services/rb-data.service';
import { SamplePreviewComponent } from '../sample-preview/sample-preview.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-reprot-schedule',
  templateUrl: './reprot-schedule.component.html',
  styleUrls: ['./reprot-schedule.component.scss']
})
export class ReprotScheduleComponent implements OnInit  {

  @Input() userDetials: any;
  @Output() objUpdated : EventEmitter<{ JobId: string, Value: boolean }>  = new EventEmitter();
  @Output() notifyBlank : EventEmitter<{ JobId: string, Value: boolean }>  = new EventEmitter();
  
  showAll: boolean = false;
  copySuccess: boolean = false;
  isLoading = false;

  schedules: any[] = [];
  schedulesHourly: any[] = [];
  schedulesDaily: any[] = [];
  schedulesWeekly: any[] = [];
  schedulesMonthly: any[] = [];

  constructor(
    private rdb: RbDataService,
    private msgbox: MsgboxService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.schedules = this.userDetials.schedule.map((schedule: any) => ({
      ...schedule,
      copySuccess: false
    }));

    this.schedulesHourly = this.schedules.filter(item => item.cronType === 'hour');
    this.schedulesDaily = this.schedules.filter(item => item.cronType === 'daily');
    this.schedulesWeekly = this.schedules.filter(item => item.cronType === 'weekly');
    this.schedulesMonthly = this.schedules.filter(item => item.cronType === 'monthly');

  }

  getTitle(cron: string): string {
    // Simplified example to match the titles in the provided image
    let expression =  cronstrue.toString(cron);
    return expression;
  }

  hasSchedules(): boolean {
    return this.schedulesHourly.length > 0 ||
           this.schedulesDaily.length > 0 ||
           this.schedulesWeekly.length > 0 ||
           this.schedulesMonthly.length > 0;
  }


  copyToClipboard(password: string, schedule: any) {
    navigator.clipboard.writeText(password).then(() => {
      this.schedules.forEach(s => s.copySuccess = false);
      schedule.copySuccess = true;
      setTimeout(() => {
        schedule.copySuccess = false;
      }, 2000);
    });
  }

  onToggleClick(event: Event) {
    event.stopPropagation();
  }

  onToggleNotifyBlank(active: boolean, jobID: string) {
    const schedule = this.schedules.find(s => s.jobID === jobID);

    if (schedule) {
      schedule.notifyBlacnkAttahcment = active;
      this.OnActiveNotifyBlank(schedule.jobID, this.userDetials.isPublish, active);
      this.objUpdated.emit({JobId : jobID, Value: active }); 
    }
  }

  OnActiveNotifyBlank(jobID: string, isPublish: boolean, isActive: boolean): void {
    this.rdb.UpdateReportConfiguration(jobID,3, isPublish, isActive).then(
      (response: AxiosResponse<any>) => {
        if (response.status === 200) {
          if (isActive) {
            this.msgbox.success("Notify Blank Attachment is Activated!", "");
          } else {
            this.msgbox.warning("Notify Blank Attachment is Deactivated!", "");
          }
        } else {
          console.log('Report status update failed');
        }
      },
    );
  }

  onToggle(active: boolean, jobID: string) {
    const schedule = this.schedules.find(s => s.jobID === jobID);

    if (schedule) {
      schedule.notifyOnlyOnData = active;
      this.OnActive(schedule.jobID, this.userDetials.isPublish, active);
      this.objUpdated.emit({JobId : jobID, Value: active }); 
    }
  }

  OnActive(jobID: string, isPublish: boolean, isActive: boolean): void {
    this.rdb.UpdateReportConfiguration(jobID,2, isPublish, isActive).then(
      (response: AxiosResponse<any>) => {
        if (response.status === 200) {
          if (isActive) {
            this.msgbox.success("Generate Report when data is available is Activated!", "");
          } else {
            this.msgbox.warning("Generate Report when data is available is Deactivated!", "");
          }
        } else {
          console.log('Report status update failed');
        }
      },
    );
  }

  Previewreport(jobID: string) {
    this.dialog.open(SamplePreviewComponent, {
      //  width: '80vw',
      data: { jobID: jobID, reportId: this.userDetials.reportId, mastercolumn:this.userDetials.masterColumns}
    });
  }
  
  getFormattedEmailTooltip(emailTo: string | undefined): string {
    if (!emailTo) return '';
    return emailTo.split(',').join('\n'); 
  }
}
