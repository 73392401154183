import { Injectable } from '@angular/core';
import axios, { AxiosInstance } from 'axios';

@Injectable()
export class SessionExpiredInterceptor {
  private axiosInstance: AxiosInstance;
  private sessionExpiredAlertShown: boolean = false; 

  constructor() {
    this.axiosInstance = axios.create({
      withCredentials: true,
    });

    this.axiosInstance.interceptors.response.use(
      (response) => response, 
      (error) => {
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data.message === 'Session Expired' &&
          !this.sessionExpiredAlertShown 
        ) {
          this.showSessionExpiredPopup();
          this.sessionExpiredAlertShown = true; 
          return Promise.reject('Session Expired'); 
        }
        return Promise.reject(error); 
      }
    );
  }

  getAxiosInstance(): AxiosInstance {
    return this.axiosInstance;
  }

  private showSessionExpiredPopup(): void {
    alert('Session expired, Please relaunch the application.');
  }

  resetSessionExpiredAlertFlag(): void {
    this.sessionExpiredAlertShown = false;
  }
}

export const axiosService = new SessionExpiredInterceptor();
