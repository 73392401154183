import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Inject, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RBOrganizationReportsDefinition, RBReports } from 'src/app/helpers/app-models';
import { RbDataService } from 'src/app/services/rb-data.service';

@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.scss']
})
export class NewReportComponent {

  searchTerm: string = '';
  reports: RBOrganizationReportsDefinition[] = [];
  showPopup: boolean = false;
  selectedReportId: number | null = null;
  report: any;
  selectedTab: string = "Daily Reports";  // Default tab
  tabList = [];  // Initialized as an empty array
  isLoading = true; 
  searchResults: { title: string; category: string; id: number }[] = [];
  _reportsList: RBReports[] = [];  
  isSearchMode: boolean = false; // Indicates if we are in search mode
  filteredReports: RBReports[] = []; 
  IsClearOn = false;
  arrowkeyLocation : number = 0
  @ViewChild('cstSearch') cstSearchEle; // Selects all dropdown elements.

  @Output() editReport = new EventEmitter<{ reportId: number, action: number }>();
  @Output() onReportSelected = new EventEmitter<RBOrganizationReportsDefinition>();

  constructor(
    private router: Router,
    private rdb: RbDataService,
    public dialogRef: MatDialogRef<NewReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.LoadAll();
  }

  LoadAll() {
    this.rdb.GetAllMasterSlaveReports().then((res) => {
      this._reportsList = res.data;
      this.generateTabs();
      this.filteredReports = this._reportsList.filter(report => report.category === this.selectedTab);
      this.isLoading = false;
    });
  }

  onSearch() {
    const searchTermLower = this.searchTerm.toLowerCase();
    if (this.searchTerm === '') {
      this.searchResults = [];
      this.isSearchMode = false;
      this.filteredReports = this._reportsList.filter(report => report.category === this.selectedTab);
    } else {
      this.searchResults = this._reportsList
        .filter(report =>
          report.title.toLowerCase().includes(searchTermLower) ||
          report.description.toLowerCase().includes(searchTermLower)
        )
        .map(report => ({
          title: report.title,
          category: report.category,
          id: report.id
        }));
    }
    this.arrowkeyLocation = 0
  }
  
  navigateToReport(result: { title: string; category: string; id: number }) {
    this.isSearchMode = true; 
    this.IsClearOn = this.selectedTab == result.category
    this.selectedTab = result.category;  
    this.filteredReports = this._reportsList.filter(report => report.id === result.id);
    this.searchResults = [];
    this.searchTerm=result.title;
  }
  
  clearSearch() {
    this.searchTerm = '';
    this.searchResults = [];
    this.isSearchMode = false;
    this.arrowkeyLocation = 0
    // Reset the filtered reports to show all reports in the selected tab
    this.filteredReports = this._reportsList.filter(report => report.category === this.selectedTab);
    this.IsClearOn=false;
  }

  generateTabs() {
    const uniqueCategories = [...new Set(this._reportsList.map(report => report.category))];
    this.tabList = uniqueCategories.map(category => ({ Htext: category }));
    this.selectedTab = this.tabList.length > 0 ? this.tabList[0].Htext : ''; // Set the default tab to the first one
  }

  get allReports(): RBReports[] { 
    return this._reportsList.filter(report => report.category === this.selectedTab);
  }
  
  onProceed(reportId: number, action: number=0) {
    if (reportId !== undefined && action !== undefined) {
      this.dialogRef.close();
      this.editReport.emit({ reportId, action }); // emit the event to the parent component
      if(action == 0 ){
        this.router.navigate(['/create'], { queryParams: { reportId : btoa(reportId.toString()) } });
      }else{
        this.router.navigate(['/edit'], { queryParams: { reportId : btoa(reportId.toString()) , action : btoa(action.toString())  } });
      }
    } else {
      console.error('Report ID or action is undefined');
    }
  }

  showSlaveReports(report: RBReports): void {
    const titles = report.slaveReports.map(slaveReport => slaveReport.title).join(', ');
    alert(`Slave Reports: ${titles}`);
  }

  closePopup() {
    this.showPopup = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  
  getTabData(event: any): void { 
    if(this.IsClearOn && this.searchTerm){
      this.clearSearch();
    }
    this.IsClearOn = true
    this.selectedTab = event;
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key == 'ArrowDown') {
      //let index = this.filteredReports.findIndex((j, i) => j.id == this.arrowkeyLocation)
      if (this.arrowkeyLocation < this.searchResults.length) {
         this.cstSearchEle? this.cstSearchEle.nativeElement.children[++this.arrowkeyLocation]?.focus():''
         this.cstSearchEle? this.cstSearchEle.nativeElement.children[this.arrowkeyLocation]?.scrollIntoView({ behavior: 'smooth', block: 'center' }):''
         event.preventDefault();
      }
    }
    else if (event.key == 'ArrowUp') {
      if (this.arrowkeyLocation > 0) {
        this.cstSearchEle? this.cstSearchEle.nativeElement.children[--this.arrowkeyLocation]?.focus():''
        this.cstSearchEle? this.cstSearchEle.nativeElement.children[this.arrowkeyLocation]?.scrollIntoView({ behavior: 'smooth', block: 'center' }):''
        event.preventDefault();
     }
    }
    else if(event.key == 'Enter'){
      let report = this.searchResults[this.arrowkeyLocation]
      this.navigateToReport(report)
      event.preventDefault();
    }
    else if(event.key == 'Tab'){
      event.preventDefault();
    }
  }
}
