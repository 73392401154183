
<ng-container>
  <app-preview *ngIf="showDetails"
               style="z-index: 20; position: absolute; width: 100%; height: 100%;"
               (CloseDetials)="closeUserDetails($event)"
               [userInfo]="selectedUserdat">
  </app-preview>

  <p-breadcrumb
    class="custom-breadcrumb"
    [style]="{
    'background-color': '#EFEFEF',
    'color': 'black !important',
    'height':'25px',
    }"
  [model]="items">
  </p-breadcrumb>
  <div class="row mt-2" style="margin-left: 2px;">
    <div class="col-sm-7 d-flex" >
      <button class="main-button" id="All" (click)="setFilter('All')"  [ngClass]="{'active-filter': filter === 'All'}">All ({{allCount}})</button>
      <div class="divider-filter"></div>
      <button class="main-button" id="Active" (click)="setFilter('Published')" [ngClass]="{'active-filter': filter === 'Published'}">Published ({{publishedCount}})</button>
      <button class="main-button" id="Active"  (click)="setFilter('Unpublished')" [ngClass]="{'active-filter': filter === 'Unpublished'}">UnPublished ({{unpublishedCount}})</button>
      <button class="main-button" id="InActive" (click)="setFilter('Draft')" [ngClass]="{'active-filter': filter === 'Draft'}">Draft ({{draftCount}})</button>
      <div class="divider-filter"></div>
      <button class="main-button" id="InActive" (click)="setFilter('No-Schedules')" [ngClass]="{'active-filter': filter === 'No-Schedules'}">No-Schedules ({{noSchedulesCount}})</button>
      <div class="divider-filter"></div>
      <div>
        <div >
          <select style="padding: 4px;margin-left: 1vw;"
                  [(ngModel)]="selectedMenu"
                  (change)="onMenuChange(selectedMenu)">
            <option value="">Category</option>
            <option *ngFor="let menu of distinctMenus" [value]="menu">{{ menu }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col-sm-5 d-flex ">
      <div class="border-search d-flex  mr-3 p-1" 
      [ngStyle]="{'width': issuperadmin ? '55%' : '70%','margin-left': issuperadmin ? '1vw' : '3vw'}" 
      *ngIf="issuperadmin !== null">
        <div class="search-divider">
          <mat-icon title="Search">search</mat-icon>
        </div>
        <input type="text" class="search-input" placeholder="Search Reports" [(ngModel)]="searchText">
        <em class="material-icons cursor"
            *ngIf="searchText !== null && searchText !== ''"
            (click)="clearSearch()">close</em>
      </div>
      <div style="margin-left: 1vw;">
        <div class="title-wrap d-flex align-items-center py-1" style="height: 30px;">
          <div class="header-wrap p-0">
            <div class="data-table btn btn-primary" (click)="navigateToNewReport()">Create Report</div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="issuperadmin==true" style="margin-left: 1vw;">
        <div class="title-wrap d-flex align-items-center py-1" style="height: 30px;">
          <div class="header-wrap p-0">
            <div class="data-table btn btn-primary" (click)="navigateToCustomReport()">Custom Report</div>
          </div>
        </div>
      </div> -->
    </div>


  </div>
  <!-- <div class="col-sm-12 d-flex mt-2 sub-menu-height">
    <div class="col-sm-8 d-flex px-0">
      <button id="All" class="ml-0"
              (click)="setFilter('All')"
              >
        All ({{allCount}})
      </button>

      <div class="divider-filter"></div>

      <button id="Active"
              (click)="setFilter('Published')"
              [ngClass]="{'active-filter': filter === 'Published'}">
        Published ({{publishedCount}})
      </button>

      <button id="Active"
              (click)="setFilter('Unpublished')"
              [ngClass]="{'active-filter': filter === 'Unpublished'}">
  	    Unpublished ({{unpublishedCount}})
      </button>

      <button id="InActive" class="ml-0"
              (click)="setFilter('Draft')"
              [ngClass]="{'active-filter': filter === 'Draft'}">
        Draft ({{draftCount}})
      </button>

      <div class="divider-filter"></div>

      <button id="InActive" class="ml-0"
              (click)="setFilter('No-Schedules')"
              [ngClass]="{'active-filter': filter === 'No-Schedules'}">
        No-Schedules ({{noSchedulesCount}})
      </button>

      <div class="divider-filter ml-2"></div>

      <div style="padding: 10px;"></div>
      <div class="col-sm-3 p-0 ml-2 d-flex">
        <select class="col-sm-6 ml-3 pl-2 work-wrap"
                [(ngModel)]="selectedMenu"
                (change)="onMenuChange(selectedMenu)">
          <option value="">Category</option>
          <option *ngFor="let menu of distinctMenus" [value]="menu">{{ menu }}</option>
        </select>
      </div>
    </div>

    <div class="col-sm-4 p-0 ml-2 d-flex">
      <div class="border-search d-flex col-sm-7 mr-3 p-1">
        <div class="search-divider">
          <mat-icon title="Search">search</mat-icon>
        </div>
        <input type="text" class="search-input" placeholder="Search Reports" [(ngModel)]="searchText">
        <em class="material-icons cursor"
            *ngIf="searchText !== null && searchText !== ''"
            (click)="clearSearch()">close</em>
      </div>
      <div style="padding-right: 20px;"></div>
      <div class="title-wrap d-flex align-items-center py-1" style="height: 30px;">
        <div class="header-wrap p-0">
          <div class="data-table btn btn-primary" (click)="navigateToNewReport()">Create Report</div>
        </div>
      </div>
    </div>
  </div> -->

  <div style="padding-top: 10px;"></div>
  <div class="separation-line"></div>

  <!-- grid -->
  <div>
    <app-grid-view *ngIf="!showloader" [reports]="allReports"
                   [filter]="filter"
                   [searchText]="searchText"
                   [selectedMenu]="selectedMenu"
                   (selectedCard)="cardSelect($event)">
    </app-grid-view>

    <div class="px-4 w-100" *ngIf="showloader">
      <app-shimmerui [line]="8"></app-shimmerui>
    </div>
  </div>
</ng-container>
