import { Injectable } from "@angular/core";
import * as appConsts from '../../vdx-filters-builder/helpers/stats-constants';

@Injectable({
  providedIn: "root",
})
export class VdxDropdownsDataManagerService {
  constructor() {}

  data: any;

  // FetchDropdownValues(reportId:number, reportTitle:string, criterias:string){
  //   this.vdxData.LoadRequiredDataForFilters(reportId, reportTitle, criterias).then(res=>{
 
  //     this.data = res.data;
  //   })
  // }

  SetData(d: any) {
    this.data = d;
  }

  GetData() {
    return this.data;
  }

  GetLaboratories() {
    return this.data[appConsts.VDXReportFilterTypes.LABORATORY];
  }

  GetOraganizations() {
    return this.data["organization"];
  }

  GetOraganizationsList() {
    return this.data[appConsts.VDXReportFilterTypes.ORDERING_ORGANIZATION];
  }

  GetServiceTypes() {
    return this.data["services"];
  }

  GetCytoTechnologists() {
    return this.SortAndMarkInactives(this.data["cytotechnologist"],"userRoleStatus");
  }

  GetCaseType(){
    return this.data["casetype"];
  }

  GetDiagnosticians() {
    return this.SortAndMarkInactives(this.data["pathologist"],"userRoleStatus");
  }

  GetScreeningTechnologist() {
    return this.SortAndMarkInactives(this.data["screenTech"], "userRoleStatus");
  }

  GetMarshelledData(coll_name:string, active_key_name:string){
    return this.SortAndMarkInactives(this.data[coll_name],active_key_name);
  }

  GetFlags() {
    return this.data["flags"].map((d: any) => {
      return { ...d, isInactive: !d["isactive"] };
    });
  }

  SortAndMarkInactives(list: Array<any>, activeStatusKey: string) {
    let inactives = list.filter(
      (a: any) => a[activeStatusKey].toLowerCase() === "inactive"
    );
    let actives = list.filter(
      (a: any) => a[activeStatusKey].toLowerCase() !== "inactive"
    );
    let sorted = [...actives, ...inactives];
    let finalList = sorted.map((d: any) => {
      return {
        ...d,
        isInactive: d[activeStatusKey].toLowerCase() === "inactive",
      };
    });
 
    return finalList;
  }
}
