<p-breadcrumb
  class="custom-breadcrumb"
  [style]="{
    'background-color': '#EFEFEF',
    'color': 'black !important',
    'height': '25px'
  }"
  [model]="items"
  (onBreadcrumbClick)="onNavigateToHome()">
</p-breadcrumb>


<div  [ngClass]="viewType === 'small' ? 'modal-backdrop-class row m-0 absolute' : 'modal-backdrop-class row m-0 relative'">
    <div class="col-sm-5" *ngIf="viewType === 'small'"></div>
    <div [ngClass]="viewType === 'small' ? 'col-sm-7 info-card relative px-0' :
    'col-sm-12 info-card relative px-0'">
      <span class="card close_btn" (click)="CloseView()" *ngIf="viewType === 'small'">
        <mat-icon matTooltip="Close">close</mat-icon>
      </span>
      <app-shimmerui *ngIf="Loader" [line]="3" class="w-100" ></app-shimmerui>
      <div *ngIf="!Loader" >

      <div *ngFor="let report of reportdetails">

      <div class="row container-fluid py-0 px-1" style="margin-top: -2vh;" >
        <ng-container>
          <div [ngClass]="viewType !== 'small' ? 'col-sm-10' : 'col-sm-12'">
            <div class="row">
              <div class="col-sm-7">
                <span class="roleborder mx-2"></span>
                <strong class="username">{{ report?.title }}</strong>
              </div>
              <div class="col-sm-5 d-flex justify-content-end align-items-center" style="padding-left: 20px;">
                <ng-container *ngIf="report.isPublish">
                 <label *ngIf="report.isActive"> Published </label> &nbsp;&nbsp;
                 <label *ngIf="!report.isActive">Unpublished </label> &nbsp;&nbsp;
                  <app-checkbox-toggle
                    [checked]="report.isActive"
                    (onClick)="onReportActiveToggled(report)">
                  </app-checkbox-toggle>
                </ng-container>

                <div style="padding-left: 10px;"></div>
                <ng-container *ngIf="userInfo.reportType.toLowerCase() === 'new'">
                  <button class="Vdm-btn cursor d-flex"
                  (click)="OnEditReport(report.id, report.isPublish ? 1 : 2)">
                    <!-- <span><i class="fa fa-pencil mr-1" aria-hidden="true"></i></span>  -->
                    <!-- <span ><i style="font-size: 11px !important" class="bi bi-pencil"></i></span> -->
                     <mat-icon style="font-size:14px ;margin-top: 7px;margin-left: -5px;margin-right: -3px;">edit</mat-icon>
                    <label style="margin-top: 5px;" for=""> &nbsp; Edit</label>
                  </button>

                </ng-container>
              </div>
            </div>
            <div class="mb-2 ml-3 mx-3">
              <strong style="font-size: 11px;">{{ report?.category }}</strong>
              <strong  style="font-size: 11px;"> | {{ getNumberOfSchedules(report) }} Schedules</strong>
            </div>
          </div>
        </ng-container>
      </div>


        <app-tabs  [tabData]="tabList" (selectedTab)="getTabData($event)"></app-tabs>

        <div class="common-tab-wrap">
          <app-report-info *ngIf="selectedTab === 'Details'" [userDetials]="report"
          [userInfo]="userInfo"></app-report-info>
          <app-reprot-schedule *ngIf="selectedTab === 'Schedules'" 
          [userDetials]="report" 
          (objUpdated)="onObjectUpdated($event)"
          (notifyBlank)="onBlankFlagUpdated($event)"
          ></app-reprot-schedule>
          <app-report-audit  *ngIf="selectedTab === 'Audit'" [userDetials]="report"></app-report-audit>
        </div>
      </div>
    </div>
    </div>
  </div>
