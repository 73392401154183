<p-breadcrumb
  class="custom-breadcrumb"
  [style]="{
    'background-color': '#EFEFEF',
    'color': 'black !important',
    'height':'25px',
  }"
  [model]="items">
</p-breadcrumb>

<ng-container>
  <section class="lab-user-creation py-3">
    <!-- Report Information Heading -->
    <div class="d-flex ">
      <div class="vdm-heading">
        <span style="font-size: 13px;">Report Information</span>
      </div>
    </div>

    <!-- Report Information Form -->
    <div class="reportinfo d-flex mb-3">
      <div class="form-group" style="width: 30%;">
        <form [formGroup]="labUserForm">
          <app-custom-input
            labelValue="Report Name"
            formcontrolname="reportName"
            required="true"
            placeholder="Enter Report Name"
            (input)="onUserInput()">
          </app-custom-input>
        </form>
      </div>
      <div style="width: 3%;"></div>
      <div class="form-group" style="width: 68%;">
        <form [formGroup]="labUserForm">
          <app-custom-input
            labelValue="Description"
            formcontrolname="reportDescr"
            required="true"
            placeholder="Enter Description"
            (input)="onUserInput()">
          </app-custom-input>
        </form>
      </div>
    </div>

    <!-- Report Categories -->
    <div class="mb-3" style="padding: 5px;">
      <app-report-categories
        [(selected)]="_selectedReportForSave.category"
        (input)="onUserInput()"
      ></app-report-categories>
    </div>

    <!-- Filter Criteria -->
    <div class="mb-3" style="padding: 5px;">
      <app-filter-criteria
        [actualCriteria]="_master_criteria"
        [selectedCriteria]="_slave_criteria"
        (onDataChanged)="onReceivedFilterSelection($event); onUserInput()">
      </app-filter-criteria>
    </div>

    <!-- Role Mapping -->
    <div class="mb-3" style="padding: 5px;">
      <app-role-mapping
      [(selected)]="_selectedReportForSave.roles"
      (input)="onUserInput()">
      </app-role-mapping>
    </div>

    <!-- Column Configuration -->
     <div *ngIf="this._selectedReport.isMultiSheetEnabled==true" class="mb-3" style="padding: 5px;">
      <span class="label-header">Columns <label class="text-danger fw-bold fs-6">*</label></span>
      <div class="column-container" >
        <span><b>Note: Since this is a multisheet report, the columns are being displayed in the template.</b></span>
      </div>

     </div>

    <div *ngIf="this._selectedReport.isMultiSheetEnabled==false" class="mb-3" style="padding: 5px;" >
      <span class="label-header">Choose Columns<label class="text-danger fw-bold fs-6">*</label>
      </span>

      <!-- Column container where placeholder or chips will appear -->
      <div class="column-container" title="Choose Columns" (click)="toggleColumnConfig()">


        <!-- Placeholder for empty column selection -->
        <div *ngIf="userSelectionListArray.length === 0" class="placeholder-text text-muted d-flex align-items-center justify-content-between">
          <span>Please select columns</span>
          <i 
            class="dropdown-icon bi" 
            [ngClass]="isColumnConfigExpanded ? 'bi-chevron-up' : 'bi-chevron-down'">
          </i>
        </div>        

        <!-- Display selected columns -->
        <div *ngIf="userSelectionListArray.length > 0" class="chip-container">

          <mat-chip-listbox>
            <mat-chip *ngFor="let column of userSelectionListArray" color="accent"
              style="padding-bottom: 8px; background-color: #1f79ce;">
              <mat-label style="color: #fff;"> {{ column }} </mat-label>
              <mat-icon class="remove-icon" (click)="RemoveItem(column, $event)" style="color: #fff;">cancel</mat-icon>
            </mat-chip>
          </mat-chip-listbox>
          <i 
          class="dropdown-iconn bi"  
          [ngClass]="isColumnConfigExpanded ? 'bi-chevron-up' : 'bi-chevron-down'">
        </i>
        </div>
      </div>

      <!-- Column Configuration Component -->
      <div *ngIf="isColumnConfigExpanded" style="padding: 5px;">
        <app-column-configuration
        [masterList]="masterListArray"
        [userSelectionList1]="slaveListArray"
        (userSelectedColumns)="handleuserSelectionListArray($event)"
        (closeConfiguration)="isColumnConfigExpanded = false"
        (input)="onUserInput()">
      </app-column-configuration>
      
      </div>
    </div>
   
    <!-- TimeZone-->
    <div class="mb-3" style="padding: 5px;">
      <app-timezone-config
        [(selected)]="_selectedReportForSave.timezone"
        (input)="onUserInput()"
      ></app-timezone-config>
    </div>    

    <!-- Configurations -->
    <!-- <div class="mb-3" style="padding: 5px;">
      <div class="menu-header">
        <span class="left-text text-muted label-header">Configuration</span>
        <span *ngIf="this._selectedReport.isMultiSheetEnabled==false" class="right-text label-header">Choose Template</span>
        <span *ngIf="this._selectedReport.isMultiSheetEnabled==true" class="right-text label-header">Report Template</span>
      </div>

      <div class="row-container">
      <div class="menu-container ">
        <div class="text-primary">Statistics Menu</div>
        <div class="menu-item ">
          <span>Generate Report when data is available &nbsp;</span>
          <app-checkbox-toggle [checked]="_selectedReportForSave.isGenerate"
          (onClick)="_selectedReportForSave.isGenerate = !_selectedReportForSave.isGenerate"
          ></app-checkbox-toggle>
        </div>
        <div class="text-primary mt-2">Email Schedule</div>
        <div class="menu-item ">
          <span>Notify Blank Attachment &nbsp;</span>
          <app-checkbox-toggle [checked]="_selectedReportForSave.NotifyBlankAttachment"
          (onClick)="_selectedReportForSave.NotifyBlankAttachment = !_selectedReportForSave.NotifyBlankAttachment"
          ></app-checkbox-toggle>
          
        </div>
        <div class="menu-item mt-1">
          <span>Email When data is Available &nbsp;</span>
          <app-checkbox-toggle [checked]="_selectedReportForSave.notifyOnlyOnData"
          (onClick)="_selectedReportForSave.notifyOnlyOnData = !_selectedReportForSave.notifyOnlyOnData"
          ></app-checkbox-toggle>
        </div>
      </div>


        <div class="Template-container">      
          <div class="flex-grow-1" *ngIf="this._selectedReport.isMultiSheetEnabled==false">
            <input
              type="file"
              #templateFile
              class="form-control"
              accept=".xls,.xlsx"
              (change)="onFileSelectedToUpload($event)"
            />
          </div>
          <div *ngIf="_selectedReport.templateFile" class="chip-container">
            <mat-chip-listbox>
              <mat-chip color="accent" style="padding-bottom: 8px; background-color: #1f79ce;">
                <mat-icon class="download-icon" (click)="onDownloadTemplate()" 
                  style="color: #fff; margin-right: 10px !important;"  matTooltip="Download">download
                </mat-icon>
                <mat-label style="color: #fff;">{{ _selectedReport.templateFile }}</mat-label>
                <mat-icon *ngIf="this._selectedReport.isMultiSheetEnabled==false" class="remove-icon" (click)="RemoveFile()" 
                  style="color: #fff;" matTooltip="Remove">cancel
                </mat-icon>
              </mat-chip>
            </mat-chip-listbox>
          </div>   
        </div>

      </div>
    </div> -->


    <!-- Schedule Section Heading -->
    <div class="d-flex mb-3">
      <div class="vdm-heading">
        <span style="font-size: 13px;">Schedule</span>
      </div>
    </div>

    <!-- Delete Popup -->
    <div *ngIf="showConfirmationPopup" class="popup-overlay"></div>
    <div *ngIf="showConfirmationPopup" class="popup">
      <div class="popup-content">
        <h3>Confirm Deletion</h3>
        <h4>Are you sure you want to delete this schedule?</h4>
        <div class="popup-actions">
          <button class="btn-cancel" (click)="closeConfirmationPopup()">Cancel</button>
          <button class="btn-add" (click)="confirmDeletion()">Delete</button>
        </div>
      </div>
    </div>

    <!-- Return Popup -->
    <div *ngIf="showPopup" class="popup-overlay"></div>
    <div *ngIf="showPopup" class="popup">
      <div class="popup-content">
        <h3>Unsaved Changes</h3>
        <h4>You have unsaved changes. Are you sure you want to leave?</h4>
        <div class="popup-actions">
          <button class="btn-cancel" (click)="confirmClose()">Leave</button>
          <button class="btn-add" style="background-color: #1f79ce;" (click)="confirmStay()">Stay</button>
        </div>
      </div>
    </div>

    <!-- Schedules -->
    <div *ngIf="_loadSchedules && _showReportDetails" class="d-flex flex-column justify-content-start gap-2">
      <div class="px-4 w-100" *ngIf="showloader">
        <app-shimmerui [line]="4"></app-shimmerui>
      </div>
      <div *ngFor="let schedule of _schedules; let i = index">
        <app-criteria-configuration
          [schedule]="schedule"
          [index]="i"
          (onDelete)="removeSchedule($event, schedule.jobId, schedule.reportId, this._selectedReportForSave.action)"
          [dataFilters]="_filtersAndData"
          (input)="onUserInput()">
        </app-criteria-configuration>
      </div>
      <div class="text-center pb-3" *ngIf="!showloader">
        <button class="col-sm-3 " style="background-color: #1f79ce; color: #F5F5F5;border: none;border-radius: 5px;min-height:4vh;" (click)="addNewSchedule() ">
          <label> <span style="font-size: 20px;margin-right: 5px;">+</span>Add Schedule</label></button>
      </div>
    </div>
  </section>

</ng-container>

<!-- Footer -->
<div class="footer">

  <div class="d-flex flex-row bg-black-alpha-10 p-2">
    <div class="flex-fill ">

    </div>
    <div class="d-flex flex-row gap-3">
      <button pButton class="btn-sec" (click)="showPopup = true">Return</button>
	    <button pButton class="btn-pri" *ngIf="this.action==1 || this.action==2" (click)="Save(this.action)">Save</button>
      <button pButton class="btn-pri" *ngIf="this.action==0" (click)="Save(2)">Save As Draft</button>
      <button pButton class="btn-pri" *ngIf="this.action==2" (click)="Save(2,true)">Publish Report</button>  
      <button pButton class="btn-pri" *ngIf="this.action==0"  (click)="Save(1)">Publish Report</button>  
    </div>
  </div>
</div>

