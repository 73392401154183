import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ShimmeruiComponent } from './shared/shimmerui/shimmerui.component';
import { ToggleComponent } from './shared/toggle/toggle.component';
import { FormGroupDirective, FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomInputComponent } from './shared/custom-input/custom-input.component';
import { HomeComponent } from './report-design/home/home.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AxioHelper } from './helpers/axios-helper';
import { GridViewComponent } from './report-design/main-reports/grid-view/grid-view.component';
import { ReprotScheduleComponent } from './report-design/main-reports/reprot-schedule/reprot-schedule.component';
import {ReportInfoComponent} from './report-design/main-reports/report-info/report-info.component';
import { PreviewComponent } from './report-design/main-reports/preview/preview.component';
import { NewReportComponent } from './report-design/main-new-report/new-report/new-report.component';
import { ToastrModule } from 'ngx-toastr';
import { MatDialogModule } from '@angular/material/dialog';
import { TabsComponent } from './report-design/main-reports/tabs/tabs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CheckboxToggleComponent } from './shared/checkbox-toggle/checkbox-toggle.component';
import { BootstrapIconComponent } from './shared/bootstrap-icon/bootstrap-icon.component';
import { ReportConfigurationComponent } from './report-design/main-new-report/report-configuration/report-configuration.component';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReportCategoriesComponent } from './report-design/main-new-report/report-configuration/report-categories/report-categories.component';
import { DropdownModule } from 'primeng/dropdown';
import { RoleMappingComponent } from './report-design/main-new-report/report-configuration/role-mapping/role-mapping.component';
import { CriteriaConfigurationComponent } from './report-design/main-new-report/report-configuration/criteria-configuration/criteria-configuration.component';
import { CronExpressionComponent } from './report-design/main-new-report/report-configuration/cron-expression/cron-expression.component';
import { EmailConfigComponent } from './report-design/main-new-report/report-configuration/email-config/email-config.component';
import { FiltersBuilderComponent } from './report-design/main-new-report/report-configuration/criteria-configuration/filters-builder/filters-builder.component';
import { VdxFiltersBuilderComponent } from './report-design/vdx-filters-builder/vdx-filters-builder.component';
import { ConfigPanelComponent } from './shared/ui/config-panel/config-panel.component';
import { DatesConfigComponent } from './shared/ui/dates-config/dates-config.component';
import { ServiceTypeDropdownComponent } from './report-design/vdx-filters-builder/vdx-filter-dropdowns/service-type-dropdown/service-type-dropdown.component';
import { VdxFilterCommonDropdownComponent } from './report-design/vdx-filters-builder/vdx-filter-dropdowns/vdx-filter-common-dropdown/vdx-filter-common-dropdown.component';
import { VdxGenericObjectDropdownComponent } from './report-design/vdx-filters-builder/vdx-filter-dropdowns/vdx-generic-object-dropdown/vdx-generic-object-dropdown.component';
import { VdxGenericSimpleDropdownComponent } from './report-design/vdx-filters-builder/vdx-filter-dropdowns/vdx-generic-simple-dropdown/vdx-generic-simple-dropdown.component';
import { VdxLabsDropdownComponent } from './report-design/vdx-filters-builder/vdx-filter-dropdowns/vdx-labs-dropdown/vdx-labs-dropdown.component';
import { VdxOrderingOrgListsOfListComponent } from './report-design/vdx-filters-builder/vdx-filter-dropdowns/vdx-ordering-org-lists-of-list/vdx-ordering-org-lists-of-list.component';
import { MultiselectSearchControlComponent } from './report-design/vdx-filters-builder/vdx-shared/multiselect-search-control/multiselect-search-control.component';
import { NumbericInputControlComponent } from './report-design/vdx-filters-builder/vdx-shared/numberic-input-control/numberic-input-control.component';
import { OtherOrderingOrgSearchComponent } from './report-design/vdx-filters-builder/vdx-shared/other-ordering-org-search/other-ordering-org-search.component';
import { VdxMultiSelectorBoxComponent } from './report-design/vdx-filters-builder/vdx-shared/vdx-multi-selector-box/vdx-multi-selector-box.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiselectListItemComponent } from './report-design/vdx-filters-builder/vdx-shared/multiselect-search-control/multiselect-list-item/multiselect-list-item.component';
import { VdxBulkLaboratorySelectorComponent } from './report-design/vdx-filters-builder/filter-components/vdx-bulk-laboratory-selector/vdx-bulk-laboratory-selector.component';
import { VdxBulkOrganizationSelectorComponent } from './report-design/vdx-filters-builder/filter-components/vdx-bulk-organization-selector/vdx-bulk-organization-selector.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FilterCriteriaComponent } from './report-design/main-new-report/report-configuration/criteria-configuration/filter-criteria/filter-criteria.component';
import { TimePickerControlComponent } from './shared/ui/time-picker-control/time-picker-control.component';
import { CalendarModule } from 'primeng/calendar';
import { ColumnConfigurationComponent } from './report-design/main-new-report/report-configuration/column-configuration/column-configuration.component';
import { ListboxModule } from 'primeng/listbox';
import { TableModule } from 'primeng/table';
import { ReportAuditComponent } from './report-design/main-reports/report-audit/report-audit.component';
import { ButtonComponent } from './shared/button/button.component';
import { SearchBoxComponent } from './shared/search-box/search-box.component';
import { CdkDrag, CdkDropList, DragDropModule } from '@angular/cdk/drag-drop'; 
import { MatMenuModule } from '@angular/material/menu';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confimation-dialog.component';
import { MatChipsModule } from '@angular/material/chips'; // Import MatChipsModule
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { VdxAlgorithmOptionsComponent } from './report-design/vdx-filters-builder/filter-components/vdx-algorithm-options/vdx-algorithm-options.component';
import { EmailInputControlComponent } from './report-design/main-new-report/report-configuration/email-config/email-input-control/email-input-control.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SamplePreviewComponent } from './report-design/main-reports/sample-preview/sample-preview.component';
import { TimezoneConfigComponent } from './report-design/main-new-report/report-configuration/timezone-config/timezone-config.component';
import { CustomNewReportComponent } from './report-design/custom-report/custom-new-report/custom-new-report.component';
import { SessionExpiredInterceptor } from './services/session-expired.interceptor';
import { FilterCheckboxControlComponentComponent } from './report-design/vdx-filters-builder/vdx-shared/filter-checkbox-control-component/filter-checkbox-control-component.component';

@NgModule({
  declarations: [
    AppComponent,
    ShimmeruiComponent,
    ToggleComponent,
    CustomInputComponent,
    HomeComponent,
    GridViewComponent,
    ReprotScheduleComponent,
    ReportInfoComponent,
    PreviewComponent,
    NewReportComponent,
    TabsComponent,
    CheckboxToggleComponent,
    BootstrapIconComponent,
    ReportConfigurationComponent,
    ReportCategoriesComponent,
    RoleMappingComponent,
    CriteriaConfigurationComponent,
    CronExpressionComponent,
    EmailConfigComponent,
    FiltersBuilderComponent,
    VdxFiltersBuilderComponent,
    ConfigPanelComponent,
    DatesConfigComponent,
    ServiceTypeDropdownComponent,
    VdxFilterCommonDropdownComponent,
    VdxGenericObjectDropdownComponent,
    VdxGenericSimpleDropdownComponent,
    VdxLabsDropdownComponent,
    VdxOrderingOrgListsOfListComponent,
    MultiselectSearchControlComponent,
    NumbericInputControlComponent,
    OtherOrderingOrgSearchComponent,
    VdxMultiSelectorBoxComponent,
    MultiselectListItemComponent,
    VdxBulkLaboratorySelectorComponent,
    VdxBulkOrganizationSelectorComponent,
    FilterCriteriaComponent,
    TimePickerControlComponent,
    ColumnConfigurationComponent,
    ReportAuditComponent,
    ButtonComponent,
    SearchBoxComponent,
    ConfirmationDialogComponent,
    VdxAlgorithmOptionsComponent,
    EmailInputControlComponent,
    SamplePreviewComponent,
    TimezoneConfigComponent,
    CustomNewReportComponent,
    FilterCheckboxControlComponentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({timeOut: 4000,positionClass:'toast-top-right', preventDuplicates:true}),
    MatDialogModule,
    MatTabsModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSlideToggleModule,
    DropdownModule,
    OverlayPanelModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatAutocompleteModule,
    CalendarModule,
    ListboxModule,
    TableModule,
    DragDropModule,
    MatMenuModule,
    CdkDropList, 
    CdkDrag,
    MatChipsModule,
    BreadcrumbModule, 
    MatProgressSpinnerModule,
    HttpClientModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionExpiredInterceptor,
      multi: true,
    },
    AxioHelper,
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
