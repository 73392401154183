import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MainAppUtils } from 'src/app/helpers/main-app-utils';
import * as consts from "../../helpers/stats-constants";
import { VdxDropdownsDataManagerService } from '../../vdx-helpers/vdx-dropdowns-data-manager.service';

@Component({
  selector: 'vr-vdx-generic-object-dropdown',
  templateUrl: './vdx-generic-object-dropdown.component.html',
  styleUrls: ['./vdx-generic-object-dropdown.component.scss']
})
export class VdxGenericObjectDropdownComponent implements OnInit {
  list = new Array<any>();

  @Input() filterType: string = "";
  @Input() label: string = "";
  @Input() labelCss :string = '';
  @Input() initValuesCsv:string = '';
  @Output() onSelected = new EventEmitter<string>();

  param_checkBoxLabel = "";
  param_listDataHeader = "";
  param_listItemLabelName = "";
  param_itemTextDimKeyName = "";
  param_listItemKeyName = "";
  param_KEY = "";

  _utils = new MainAppUtils();

  constructor(private vdxDdlManager: VdxDropdownsDataManagerService) {}

  ngOnInit(): void {
    console.log(this.initValuesCsv);
    this.PrepareList();
  }

  PrepareList() { 
    this.param_checkBoxLabel = this.label;
    this.param_listDataHeader = this.label;
    
    if (this.filterType === consts.VDXReportFilterTypes.CASE_TYPE 
      ) {
      this.param_listItemLabelName = "caseType";
      this.param_KEY = "caseType";
      this.param_listItemKeyName = this.param_KEY;
      this.list = this._utils.getData(this.vdxDdlManager,this.filterType);// this.vdxDdlManager.GetData()[this.filterType];
    } 
    else if (this.filterType === consts.VDXReportFilterTypes.LABORATORY){
     
      this.param_listItemLabelName = "laboratoryNames";
      this.param_KEY = "laboratory";
      this.param_listItemKeyName = this.param_KEY;
      this.list = this._utils.getData(this.vdxDdlManager,this.filterType);
    }
    else if (
      this.filterType === consts.VDXReportFilterTypes.CASE_STATUS_LIST  
    ) {
      this.param_listItemLabelName = "cStatus";
      this.param_KEY = "cStatus";
      this.param_listItemKeyName = this.param_KEY;
      this.list = this._utils.getData(this.vdxDdlManager,this.filterType); //this.list = this.vdxDdlManager.GetData()[this.filterType];
    } 
    else if (
      this.filterType === consts.VDXReportFilterTypes.ACCESSIONED_BY ||
      this.filterType === consts.VDXReportFilterTypes.PERFORMED_BY ||
      this.filterType === consts.VDXReportFilterTypes.SCREENTECHNOLOGIST ||
      this.filterType === consts.VDXReportFilterTypes.PATHOLOGISTS ||
      this.filterType === consts.VDXReportFilterTypes.DIAGNOSTICIAN ||
      this.filterType === consts.VDXReportFilterTypes.SALESEXECUTIVE
    ) {
      this.param_listItemLabelName = "formattedDisplayName";
      this.param_itemTextDimKeyName = "isInactive";
      this.param_KEY = "userID";
      this.param_listItemKeyName = this.param_KEY;
      this.list = this.vdxDdlManager.GetMarshelledData(this.filterType,'userRoleStatus');
    } 
    else if (
      this.filterType === consts.VDXReportFilterTypes.CYTO_TECHNO_TYPE
    ) {
      this.param_listItemLabelName = "formattedDisplayName";
      this.param_itemTextDimKeyName = "isInactive";
      this.param_KEY = "userID";
      this.param_listItemKeyName = "formattedDisplayName";
      this.list = this.vdxDdlManager.GetMarshelledData(this.filterType,'userRoleStatus');
    } 
    else if (this.filterType === consts.VDXReportFilterTypes.FLAGS) {
      this.param_listItemLabelName = "attrName";
      this.param_itemTextDimKeyName = "isInactive";
      this.param_KEY = "attrName";
      this.param_listItemKeyName = this.param_KEY;
      this.list = this.vdxDdlManager.GetFlags();
    }    
    else if (this.filterType === consts.VDXReportFilterTypes.READING_LOCATION) {
      this.param_listItemLabelName = "readingLocationDisplayName";
      this.param_itemTextDimKeyName = "";
      this.param_KEY = "readingLocationAccountName";
      this.param_listItemKeyName = this.param_KEY;
      this.list = this._utils.getData(this.vdxDdlManager,this.filterType); //this.list = this.vdxDdlManager.GetData()[this.filterType];
    } 
    else if (this.filterType === consts.VDXReportFilterTypes.HemOnc_SubCaseType) {
      this.param_listItemLabelName = "hemoncSubCasetype";
      this.param_itemTextDimKeyName = "";
      this.param_KEY = "hemoncSubCasetype";
      this.param_listItemKeyName = this.param_KEY;
      this.list = this._utils.getData(this.vdxDdlManager,this.filterType); 
    } 
    else if (this.filterType === consts.VDXReportFilterTypes.TEST_TYPE) {
      this.param_listItemLabelName = "type";
      this.param_itemTextDimKeyName = "";
      this.param_KEY = "type";
      this.param_listItemKeyName = this.param_KEY;
      this.list = this._utils.getData(this.vdxDdlManager,this.filterType); 
    } 
    else if (this.filterType === consts.VDXReportFilterTypes.CASE_STATUS) {
      this.param_listItemLabelName = "cStatus";
      this.param_itemTextDimKeyName = "";
      this.param_KEY = "cStatus";
      this.param_listItemKeyName = this.param_KEY;
      this.list = this._utils.getData(this.vdxDdlManager,this.filterType); 
    } 
    else {
      this.param_listItemLabelName = "displayName";
      this.param_KEY = this.param_listItemLabelName;
      this.param_listItemKeyName = this.param_listItemLabelName;
      this.list = this._utils.getData(this.vdxDdlManager,this.filterType); 
    }
  }
}
