<app-shimmerui *ngIf="loader" [line]="2"></app-shimmerui>
<div *ngIf="!loader" style="padding: 5px;"></div>
<section [formGroup]="labUserForm">

  <div class="row flex" style="padding-bottom: 10px; display: flex; align-items: center;">
    <app-email-input-control class="col-sm-10" labelValue="Email To *"
      [(emailIdsCsv)]="_selectedSchdule.emailTo">
    </app-email-input-control>
    <div class="col-sm-2" style="margin-top: -4vh;">
      <button class="hide" [class.--selected]="_showCC" type="button"  (click)="showCC()" >CC</button>
    <button class="hide" [class.--selected]="_showBCC" type="button" (click)="showBCC()" >BCC</button>
    </div>

  </div>

  <!-- CC and BCC Inputs in one row, each taking 50% width -->
  <div class="flex row" *ngIf="_showCC || _showBCC" style="display: flex; gap: 10px; padding-bottom: 10px;">
    <!-- Conditionally render CC field at 50% width -->
    <div *ngIf="_showCC" style="flex: 1;">
      <app-email-input-control width="60%;" labelValue="Email CC"
        [(emailIdsCsv)]="_selectedSchdule.emailCC">
      </app-email-input-control>
    </div>

    <!-- Conditionally render BCC field at 50% width -->
    <div *ngIf="_showBCC" style="flex: 1;">
      <app-email-input-control  width="40%;"  labelValue="Email BCC"
        [(emailIdsCsv)]="_selectedSchdule.emailBCC">
      </app-email-input-control>
    </div>
  </div>

  <!-- Subject and Description -->
  <div class="form-row">
    <div style="width: 40%;">

      <mat-form-field appearance="outline">
        <mat-label style="font-weight: 700;">Enter Subject *</mat-label>
        <input matInput [formControl]="EmailSubject" (keydown)="PatchSubject(EmailSubject.value)" (keyup)="PatchSubject(EmailSubject.value)" placeholder="Enter Subject">
      </mat-form-field>
    </div>
    <div style="width: 60%;">

      <mat-form-field appearance="outline">
        <mat-label  style="font-weight: 700;">Enter Description</mat-label>
        <textarea matInput  placeholder="Enter Description" rows="1" [formControl]="EmailBody" (keydown)="PatchBody(EmailBody.value)"
         (keyup)="PatchBody(EmailBody.value)" ></textarea>
      </mat-form-field>
    </div>
  </div>
</section>

<!-- Password -->
 <div style="padding: 10px;"></div>
<div>
  <div style="color: #0d6efd;">Password</div>
  <div class="form-row">
    <div class="pass-container">
      <div class="single-select-menu">
        <div class="menu-item" [class.active]="selectedType === 'dynamic'" (click)="selectType('dynamic')">Dynamic Password</div>
        <div class="menu-item" [class.active]="selectedType === 'custom'" (click)="selectType('custom')">Custom Password</div>
        <div class="menu-item" [class.active]="selectedType === 'default'" (click)="selectType('default')">Default Password</div>
      </div>
    </div>
    <div style="width: 60%;">
      <div *ngIf="selectedType === 'dynamic'" class="box-container d-flex align-items-center">
        <div>
          <img src="\assets\Icons\Info.svg">&nbsp;New Password will be generated for every report</div>
      </div>
      <div *ngIf="selectedType === 'custom'" class="menu-container d-flex align-items-center">
        <div style="height: 50px;">
          <mat-form-field appearance="outline" style="width: 100%; height: 50px;">
            <mat-label style="font-weight: 700;">Password</mat-label>
            <input  matInput [formControl]="CustomPassword"  (focusout)="PatchPassword(CustomPassword.value)" placeholder="Enter Your Custom Password">
          </mat-form-field>
        </div>
        <div style="padding: 10px;"></div>
        <div class="ml-2 d-flex align-items-center">
          <input 
          type="checkbox" 
          id="customPasswordCheckbox" 
          style="margin-right: 5px;"
          [(ngModel)]="enablePasswordForCustom" 
          (change)="updatePasswordForCustom()"
        />
          <label>Send password when report is generated</label>
        </div>
        
      </div>

      <div *ngIf="selectedType === 'default'" class="menu-container d-flex align-items-center">
        <div class="w-50">
          <span class="h5 pb-0 mb-0">{{value}}</span>
          <br />(Report Generated Month, Date and Year)
        </div>
        <div class="ml-2 d-flex align-items-center"> 
          <input type="checkbox" id="customPasswordCheckbox"  style="margin-right: 5px;" 
          [(ngModel)]="enablePasswordForDefault"
          (change)="updatePasswordForDefault()"/>
          <label >Send password when report is generated</label>
        </div>
      </div>
  </div>
</div>
