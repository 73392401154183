
<div class="row">
  <div style="padding-top: 5px;">Distribution Type</div>
  </div>
  <div class="cron-container">
    <div class="cron" style="width: 23%;">
      <div class="single-select-menu" style="gap: 11px;">
        <div class="menu-Data" [class.active]="selectedType === 'Hourly'" (click)="selectType('Hourly')">Hourly</div>
        <div class="menu-Data" [class.active]="selectedType === 'Daily'" (click)="selectType('Daily')">Daily</div>
        <div class="menu-Data" [class.active]="selectedType === 'Weekly'" (click)="selectType('Weekly')">Weekly</div>
        <div class="menu-Data" [class.active]="selectedType === 'Monthly'" (click)="selectType('Monthly')">Monthly</div>
      </div>
    </div>
    <div class="cron" style="width: 76.5%;" *ngIf="this.selectedType != ''" >
      <div *ngIf="selectedType === 'Hourly'" class=" d-flex align-items-center" [formGroup]="hourlyForm">
        <label>Every</label>&nbsp;
        <!-- <input type="number" class="d-flex flex-row align-items-center gap-2" style="width: 10%;" [(ngModel)]="cronData.hourlyEvery"> -->
         <div>
        <select formControlName="Hhours" class="menu-item">
          <option *ngFor="let hour of hours" [value]="hour">{{hour}}</option>
        </select>
      </div>
      <div>&nbsp;  Hour(s)</div>&nbsp;  
        <div>
          <select formControlName="Hminutes" class="menu-item">
            <option *ngFor="let minute of minutes" [value]="minute" >{{minute}}</option>
          </select>
        </div>
        <div>&nbsp; Minute(s)</div>&nbsp;
        <!--<input type="time" class="form-control d-flex flex-row align-items-center gap-2" (change)="ChangeTime()" style="width: 100px;" [(ngModel)]="cronData.startTime"> -->
        <!-- <span class="text-danger ml-1">*</span> -->
      </div>
  
      <div *ngIf="selectedType === 'Daily'"  [formGroup]="dailyForm">
        <div  >
          <div class="single-select-menu">
            <div class="menu-item"   [class.active]="selectedDaily === 'Everyday'" (click)="DailyselectType('Everyday')">Everyday</div>
            <div class="menu-item"   [class.active]="selectedDaily === 'Working Days'" (click)="DailyselectType('Working Days')" >Working Days (MON-FRI)</div>
            <div style="border-right: 1px solid grey;"></div>
            <select formControlName="Dhours" class="menu-item" >
              <option *ngFor="let hour of hours" [value]="hour">{{hour}}</option>
            </select> 
            <div style="margin-top: 0.5%;" >&nbsp;  Hour(s)</div>&nbsp;  
            <select formControlName="Dminutes" class="menu-item">
              <option *ngFor="let minute of minutes" [value]="minute" >{{minute}}</option>
            </select>
            <div style="margin-top: 0.5%;"  >&nbsp; Minute(s)</div>&nbsp; 
          </div> 
        </div> 
        </div>
  
      <div *ngIf="selectedType === 'Weekly'" [formGroup]="weeklyForm">
        <div class="single-select-menu">
          <div style="margin-top: 0.5%;"  >&nbsp; Every : </div>&nbsp; 
          <div 
            *ngFor="let day of weekDays"
            class="role-item"
            [class.selected]="cronData.weeklyDays.includes(day)"
            (click)="toggleDaySelection(day)">
            {{ day }}
          </div>
          <div style="border-right: 1px solid grey;"></div>
          <select formControlName="Whours" class="menu-item" >
            <option *ngFor="let hour of hours" [value]="hour">{{hour}}</option>
          </select> 
          <div style="margin-top: 0.5%;" >&nbsp;  Hour(s)</div>&nbsp;  
          <select formControlName="Wminutes" class="menu-item">
            <option *ngFor="let minute of minutes" [value]="minute" >{{minute}}</option>
          </select>
          <div style="margin-top: 0.5%;"  >&nbsp; Minute(s)</div>&nbsp;
        </div>
        
      
        <!-- <input type="time" class="form-control d-flex flex-row align-items-center gap-2" style="width: 100px;" [(ngModel)]="cronData.startTime"> -->
      </div>
  
      <div *ngIf="selectedType === 'Monthly'"   [formGroup]="monthlyForm">
  
        <div class="single-select-menu">
          <div class="menu-item"   [class.active]="MonthlyselectedDaily === 'OntheDay'" (click)="MonthlyselectType('OntheDay')">On the Day</div>
          <div class="menu-item"   [class.active]="MonthlyselectedDaily === 'OnheWeek'" (click)="MonthlyselectType  ('OnheWeek')" >On the Week</div>
          <div style="border-right: 1px solid grey;"></div>
          <div>
            <div *ngIf="MonthlyselectedDaily == 'OntheDay'">
              <select formControlName="daynum"   class="menu-item">
                <option
                  *ngFor="let monthDaysWithLast of selectOptions.monthDaysWithLasts"
                  [value]="monthDaysWithLast">
                  {{monthDayDisplay(monthDaysWithLast)}}
                </option>
              </select>
             
            </div>
            <div *ngIf="MonthlyselectedDaily == 'OnheWeek'">
  
              <select formControlName="monthWeek" class="menu-item">
                <option
                    *ngFor="let monthWeek of selectOptions.monthWeeks"
                    [value]="monthWeek"
                  >
                    {{monthWeekDisplay(monthWeek)}}
                  </option>
              </select>
              <label class="ml-2" style="margin-top: 0.5%;">&nbsp; Day &nbsp; </label>
              <select formControlName="DayName" class="menu-item">
                <option *ngFor="let day of selectOptions.days" [value]="day">
                  {{dayDisplay(day)}}
                </option>
              </select>
            </div>
          </div>
          <!-- <div style="border-right: 1px solid grey;"></div> -->
          <label class="ml-2" style="margin-top: 0.5%;">&nbsp; of every Month </label>
          <select formControlName="MonthNum"  class="menu-item" [(ngModel)]="cronData.monthlyMonthInterval">
            <option *ngFor="let month of monthIntervals" [value]="month">{{ month }}</option>
          </select> 
          <label class="ml-2" style="margin-top: 0.5%;">&nbsp; at time &nbsp;</label>
          <select formControlName="Mhours" class="menu-item" >
            <option *ngFor="let hour of hours" [value]="hour">{{hour}}</option>
          </select> 
          <div style="margin-top: 0.5%;" >&nbsp;  Hour(s)</div>&nbsp;  
          <select formControlName="Mminutes" class="menu-item">
            <option *ngFor="let minute of minutes" [value]="minute" >{{minute}}</option>
          </select>
          <div style="margin-top: 0.5%;"  >&nbsp; Minute(s)</div>&nbsp; 
        </div>   
    </div>
  </div>
  
  </div>
  <div class="flex-row" style="padding: 10px;width: 100%;">   
       Means <span style="background-color: #1f79ce;
      border-radius: 19px;
      padding: 0.5%;
      color: #fff;"> &nbsp; {{ this.expressionDescription }} &nbsp; </span>
  </div> 