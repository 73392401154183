import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VdxDropdownsDataManagerService } from '../../vdx-helpers/vdx-dropdowns-data-manager.service';
import * as consts from "../../helpers/stats-constants";
import { MainAppUtils } from 'src/app/helpers/main-app-utils';

@Component({
  selector: 'vr-vdx-generic-simple-dropdown',
  templateUrl: './vdx-generic-simple-dropdown.component.html',
  styleUrls: ['./vdx-generic-simple-dropdown.component.scss']
})
export class VdxGenericSimpleDropdownComponent implements OnInit {
  list = new Array<any>();
  itemKey: string = "";
  itemValue: string = "";
  //selectedValue : any;

  @Input() filterType: string = "";
  @Input() selectedValue : any ="";
  @Output() onSelected = new EventEmitter<any>();

  constructor(private vdxDdlManager: VdxDropdownsDataManagerService) {}

  ngOnInit(): void {
    this.PrepareList();
  }

  onValueSelected(e: any) {
    this.onSelected.emit(e.value);
  }

  PrepareList() {

    if(this.filterType === consts.VDXReportFilterTypes.CASE_TYPE_CATEGORY ||
      this.filterType === consts.VDXReportFilterTypes.DIAGNOSISPATHOLOGIST ||
      this.filterType === consts.VDXReportFilterTypes.TEST_STATUS ||
      this.filterType === consts.VDXReportFilterTypes.TIME_ZONE
      ){
      this.itemKey = "valData";
      this.itemValue = "keyData";
    } 
    
    else if( this.filterType === consts.VDXReportFilterTypes.SERVCIE_TYPE ||
      this.filterType === consts.VDXReportFilterTypes.MESSAGE_TYPE ||
      this.filterType === consts.VDXReportFilterTypes.DIRECTION ||
      this.filterType === consts.VDXReportFilterTypes.STATUS
    ){
      this.itemKey="value"; //value
      this.itemValue = "value"; //text-label
    }  
    
    else if(this.filterType === consts.VDXReportFilterTypes.TEST_TYPE 
    ){
      this.itemKey="type"; //value
      this.itemValue = "type"; //text-label
    }   
    
    else {
      this.itemKey = "displayName";
      this.itemValue = this.itemKey;
    }
    this.list = new MainAppUtils().getData(this.vdxDdlManager,this.filterType);
 
  }

}
