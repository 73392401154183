import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, catchError, debounceTime, of, switchMap, tap } from 'rxjs';
import { ConfigUtils } from 'src/app/helpers/config-utils';
import { MsgboxService } from 'src/app/helpers/msgbox.service';

interface User {
  userFormatedName: string;
  emailID: string;
}

@Component({
  selector: 'app-email-input-control',
  templateUrl: './email-input-control.component.html',
  styleUrls: ['./email-input-control.component.scss'],
})
export class EmailInputControlComponent {
  @Output() emailIdsCsvChange = new EventEmitter<string>();
  @Input() labelValue: string = '';

  emailControl = new FormControl();
  filteredEmails: User[] = [];
  selectedEmails: string[] = [];
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @Input() set emailIdsCsv(val: string) {
    if (val) {
      this.selectedEmails = val.split(',').map((email) => email.trim());
      this.removeInvalidEmails();
    }
  }

  constructor(
    private http: HttpClient,
    private msgbox: MsgboxService,
    private config: ConfigUtils
  ) {
    this.emailControl.valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading$.next(true)),
        switchMap((value) => this.getSuggestions(value)),
        tap(() => this.isLoading$.next(false))
      )
      .subscribe((emails: User[]) => {
        this.filteredEmails = emails;
      });
  }

  getSuggestions(value: string) {
    if (!value.trim()) {
      return of([]);
    }
    const apiUrl = this.config.BuildApiUrl(
      `GetAccountUsers?sampleText=${value}`
    );
    return this.http.get<User[]>(apiUrl).pipe(catchError(() => of([])));
  }

  addEmail(input: string | User) {
    let email = typeof input === 'string' ? input : input.emailID;

    if (!this.isValidEmail(email)) {
      this.msgbox.warning('Invalid Email ID', '');
      return;
    }

    if (this.selectedEmails.includes(email)) {
      this.msgbox.warning('Duplicate Email ID', '');
      return;
    }

    this.selectedEmails.push(email);
    this.emailIdsCsvChange.emit(this.selectedEmails.join(','));
    this.emailControl.setValue('');
  }

  removeEmail(email: string) {
    const index = this.selectedEmails.indexOf(email);
    if (index >= 0) {
      this.selectedEmails.splice(index, 1);
      this.emailIdsCsvChange.emit(this.selectedEmails.join(','));
    }
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  
  removeInvalidEmails() {
    const validEmails = this.selectedEmails.filter((email) =>
      this.isValidEmail(email)
    );
    const invalidEmails = this.selectedEmails.filter(
      (email) => !this.isValidEmail(email)
    );

    if (invalidEmails.length > 0) {
      this.msgbox.warning(
        `Invalid emails removed: ${invalidEmails.join(', ')}`,
        ''
      );
      this.selectedEmails = validEmails;
      this.emailIdsCsvChange.emit(this.selectedEmails.join(','));
    }
  }
}
