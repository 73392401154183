import { MainAppUtils } from "../../helpers/main-app-utils";
import { DateTimeUtilsService } from "src/app/helpers/date-time-utils.service";

export class VDXFilterEventAndDataHanders {
  config: any; //FilterConfig DTO from ui-models.ts
  mainUtils = new MainAppUtils();

  constructor(private dateUtils: DateTimeUtilsService) {
    this.config = { payload: {} };
    //this.config.payload = new DownloadReportRequestPayloadTemplate();
  }

  raiseDataChangedEventFn: any;

  clearDates(dates: any) {
    if (dates && dates.length >= 2) {
      dates[0] = "";
      dates[1] = "";
    }
  }

  IsToDateBeforeFrom(dts: string[]) {
    try {
      let from = new Date(dts[0]).getTime();
      let to = new Date(dts[1]).getTime();
      return from > to;
    } catch (err) {
      return false;
    }
  }
  GetValueOrNull(e: string) {
    return this.mainUtils.isObjectUndefined(e) ? "" : e;
  }

  onFilterValueChange(e: string, payloadKey: string) {
    console.warn("setting values", { e, payloadKey });
    this.config.payload[payloadKey] = this.GetValueOrNull(e);
    this.raiseDataChangedEventFn();
  }

  onFilterDateValueChange(e: any, payloadKey: string) {
    //console.warn(JSON.stringify({ e, payloadKey },null,2));
    e["dateTypeCriteria"]=payloadKey;
    this.config.payload[payloadKey] = this.GetValueOrNull(e);
    if(this.raiseDataChangedEventFn)
      this.raiseDataChangedEventFn();
  }

  onFilterNumberValueChange(e: number, payloadKey: string) {
    if (e > -1) {
      console.warn("setting values", { e, payloadKey });
      this.config.payload[payloadKey] = e;
    } else {
      delete this.config.payload[payloadKey];
    }
    this.raiseDataChangedEventFn();
  }

  onFilterTextboxInputValueChange(e: any, payloadKey: string) {
    this.config.payload[payloadKey] = this.GetValueOrNull(e.target.value);
    this.raiseDataChangedEventFn();
  }

  onTwoFilterMergedValueChange(e: string) {
    let vals = e.split("~");
    console.warn("setting values", vals);
    this.config.payload[vals[0]] = this.GetValueOrNull(vals[1]);
    this.raiseDataChangedEventFn();
  }

  //-------- SINGLE Date
  onSingleDateChange(date: any, configKey: string) {
    //let validatedDates = this.checkAndAssignDates(date);
    //if(date == null) date = '';
    if (this.mainUtils.IsValidDate(date)) {
      this.config.payload[configKey] = this.dateUtils.FormatDateToSave(
        date.toString()
      );
      this.raiseDataChangedEventFn();
    } else {
      this.config.payload[configKey] = "";
    }
  }

  //------------ DATES
  checkAndAssignDates(dt: Date[]) {
    let startDateString = "";
    let endDateString = "";

    if (this.mainUtils.IsInvalidDateObject(dt[0])) {
      //value is -> Invalid Date
      startDateString = "";
    } else {
      startDateString =
        this.dateUtils.FormatDateToSave(dt[0].toDateString()) || "";
    }
    if (this.mainUtils.IsInvalidDateObject(dt[1])) {
      endDateString = "";
    } else {
      endDateString =
        this.dateUtils.FormatDateToSave(dt[1].toDateString()) || "";
    }
    console.log("after checks", dt);
    return [startDateString, endDateString];
  }

  onDatesChange(dates: any, configKey: string) {
    let validatedDates = this.checkAndAssignDates(dates);
    this.config.datesBag = {
      ...this.config.datesBag,
      [configKey]: validatedDates,
    };
    this.raiseDataChangedEventFn();
  }

  onDateValidityStatusRecived(isProperDate: boolean, configKey: string) {
    if (!isProperDate)
      this.config.datesBag = { ...this.config.datesBag, [configKey]: ["", ""] };
  }

  onFilterValueChangeBoolean(e: boolean, payloadKey: string) {
    this.config.payload[payloadKey] = e;
    this.raiseDataChangedEventFn();
  }
}
