
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CronOptions } from './CronOptions';
import cronstrue from 'cronstrue';

function* range(start: number, end: number) {
  for (let i = start; i <= end; i++) {
    yield i;
  }
}

@Component({
  selector: 'app-cron-expression',
  templateUrl: './cron-expression.component.html',
  styleUrls: ['./cron-expression.component.scss']
})
export class CronExpressionComponent implements OnInit{

 // cronExpression = '';
 @Input() cronExpression: string = '';
 @Output() cronExpressionChange = new EventEmitter<string>();

  expressionDescription = ''
  options: CronOptions = new CronOptions();

  constructor(private fb: FormBuilder) {}
  
 isItHourlyTab = false;
  use24HourTime = false;

  selectedType: string = '';
  selectedDaily: string = 'Everyday';
  MonthlyselectedDaily: string = 'OntheDay';
  weekDays: string[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  monthIntervals: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  weekIntervals: string[] = ['First', 'Second', 'Third', 'Fourth', 'Last'];
  cronData: any = {
    startTime: '',
    hourlyEvery: '',
    dailyTime: '',
    weeklyDays: ['Monday'],
    monthlyDay: '',
    monthlyTime: '',
    monthlyOption: 'day',
    monthlyWeek: 'First',
    monthlyDayOfWeek: 'Monday',
    monthlyMonthInterval: 1
  };

  hourlyForm: FormGroup = new FormGroup({});
  dailyForm: FormGroup = new FormGroup({});
  weeklyForm: FormGroup = new FormGroup({});
  monthlyForm: FormGroup = new FormGroup({});

  public selectOptions = this.getSelectOptions();
  
  get yearDefaultChar() {
    return this.options?.cronFlavor === "quartz" ? "*" : "";
  }

  get weekDayDefaultChar() {
    return this.options?.cronFlavor === "quartz" ? "?" : "*";
  }

  get monthDayDefaultChar() {
    return this.options?.cronFlavor === "quartz" ? "?" : "*";
  }


  public minutes =  [...range(0, 59) ];

  ngOnInit(): void {
    const [defaultHours, defaultMinutes, defaultSeconds] = this.options
      ? this.options?.defaultTime.split(":").map(Number)
      : [10, 0, 0];
    // if(this.cronExpression){
    //   //console.log('In Cron expression Is empty or null.');
    // }
    this.hourlyForm = this.fb.group({
      Hhours: [1],
      Hminutes: [0], 
    });

    this.dailyForm = this.fb.group({
      days: [1],
      Dhours:[1],
      Dminutes: [0],
      hourType: [this.getHourType(0)],
    });

    this.weeklyForm = this.fb.group({ 
      Whours: [0],
      Wminutes: [0], 
      hourType:  [this.getHourType(0)],
    });

    this.monthlyForm = this.fb.group({ 
      daynum: ["1"],
      monthWeek: ["#1"],
      DayName: ["MON"],
      MonthNum: [1],
      Mhours: [0],
      Mminutes: [0], 
      hourType:  [this.getHourType(0)],
    });

    this.monthlyForm.valueChanges.subscribe((next) =>
      this.computeMonthlyCron(next)
    );
    
    this.weeklyForm.valueChanges.subscribe((next) =>
      this.computeWeeklyCron(next)
    );
    
    this.hourlyForm.valueChanges.subscribe((value) =>{ 
      this.computeHourlyCron(value);
    }); 
    this.dailyForm.valueChanges.subscribe((value) =>{ 
      this.computeDailyCron(value);
    });
    if(!this.cronExpression){
      this.selectedType = 'Hourly';
      this.cronExpression = '0 0 0/1 1/1 * ? *';
      this.expressionDescription = cronstrue.toString(this.cronExpression);
      //this.computeHourlyCron(this.hourlyForm.value);
      }
      else
      {
        this.expressionDescription = cronstrue.toString(this.cronExpression);
        
      } 
  }

  public monthDayDisplay(month: string): string {
    if (month === "L") {
      return "Last Day";
    } else if (month === "LW") {
      return "Last Weekday";
    } else if (month === "1W") {
      return "First Weekday";
    } else {
      return `${month}${this.getOrdinalSuffix(month)}`;
    }
  }

  private getOrdinalSuffix(value: string) {
    if (value.length > 1) {
      const secondToLastDigit = value.charAt(value.length - 2);
      if (secondToLastDigit === "1") {
        return "th";
      }
    }
    
    const lastDigit = value.charAt(value.length - 1);
    switch (lastDigit) {
      case "1":
        return "st";
      case "2":
        return "nd";
      case "3":
        return "rd";
      default:
        return "th";
    }
  }

  private getAmPmHour(hour: number) {
    return this.options?.use24HourTime ? hour : ((hour + 11) % 12) + 1;
  }

  selectType(type: string) {
    this.selectedType = type;
    switch (type) {
      case "Hourly":
        this.use24HourTime = false;
        this.computeHourlyCron(this.hourlyForm.value);
        break;
      case "Daily":
        this.use24HourTime = true;
        this.computeDailyCron(this.dailyForm.value);
        break;
      case "Weekly":
        this.use24HourTime = true;
        this.computeWeeklyCron(this.weeklyForm.value);
        break;
      case "Monthly":
        this.use24HourTime = true;
        this.computeMonthlyCron(this.monthlyForm.value);
        break;
      default:
        throw new Error("Invalid cron daily subtab selection");    
    } 
  }

  public dayDisplay(day: string): string {
    let d: any = {
      SUN: "Sunday",
      MON: "Monday",
      TUE: "Tuesday",
      WED: "Wednesday",
      THU: "Thursday",
      FRI: "Friday",
      SAT: "Saturday",
    };
    return d[day];
  }

  DailyselectType(type: string) {
    this.selectedDaily = type;
    this.computeDailyCron(this.dailyForm.value);
  }

  MonthlyselectType(type: string) {
    this.MonthlyselectedDaily = type;
    this.computeMonthlyCron(this.monthlyForm.value);
  }

  toggleDaySelection(day: string) {
    const index = this.cronData.weeklyDays.indexOf(day);
    if (index > -1) {
      this.cronData.weeklyDays.splice(index, 1);
    } else {
      this.cronData.weeklyDays.push(day);
    } 
    this.computeWeeklyCron(this.weeklyForm.value);
  }

  public monthWeekDisplay(monthWeekNumber: string): string {
    let mw: any = {
      "#1": "First",
      "#2": "Second",
      "#3": "Third",
      "#4": "Fourth",
      "#5": "Fifth",
      L: "Last",
    };
    return mw[monthWeekNumber];
  }

  private getSelectOptions() {
    return {
      months: this.getRange(1, 12),
      monthWeeks: ["#1", "#2", "#3", "#4", "#5", "L"],
      days: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
      minutes: this.getRange(0, 59),
      fullMinutes: this.getRange(0, 59),
      seconds: this.getRange(0, 59),
      hours: this.getRange(1, 23),
      monthDays: this.getRange(1, 31),
      monthDaysWithLasts: [
        "1W",
        ...[...this.getRange(1, 31).map(String)],
        "LW",
        "L",
      ],
      monthDaysWithOutLasts: [...[...this.getRange(1, 31).map(String)]],
      hourTypes: ["AM", "PM"],
    };
  }

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.apply(null, Array(length)).map((_, i) => i + start);
  }

  get isCronFlavorQuartz() {
    return this.options?.cronFlavor === "quartz";
  }

  private getHourType(hour: number) {
    return this.options?.use24HourTime ? undefined : hour >= 12 ? "PM" : "AM";
  }

  private hourToCron(hour: number, hourType: string) {
    if (this.options?.use24HourTime) {
      return hour;
    } else {
      return hourType === "AM"
        ? hour === 12
          ? 0
          : hour
        : hour === 12
        ? 12
        : hour + 12;
    }
  }


    get hours(): number[] {
      if(this.selectedType === "Hourly" )
        return this.use24HourTime ? [... range(1, 23)] : [... range(1, 12)];
      else
      return this.use24HourTime ? [... range(0, 23)] : [... range(0, 12)];
    } 
    
    private computeHourlyCron(state: any) {
      this.cronExpression = `${this.isCronFlavorQuartz ? 0 : ""} ${
        state.Hminutes
      } 0/${state.Hhours} 1/1 * ${this.weekDayDefaultChar} ${
        this.yearDefaultChar
      }`.trim(); 
      //console.log(this.cronExpression); 
      this.expressionDescription = cronstrue.toString(this.cronExpression);
      //console.log(this.expressionDescription);
      this.cronExpressionChange.emit(this.cronExpression);
    }
    
    private computeDailyCron(state: any) {
      //console.log(state);
      switch (this.selectedDaily) {
        case "Everyday":
          this.cronExpression = `${
            this.isCronFlavorQuartz ? 0 : ""
          } ${state.Dminutes} ${this.hourToCron(
            state.Dhours,
            state.hourType
          )} 1/${state.days} * ${this.weekDayDefaultChar} ${
            this.yearDefaultChar
          }`.trim();
          break;
        case "Working Days":
          this.cronExpression = `${
            this.isCronFlavorQuartz ? 0 : ""
          } ${state.Dminutes} ${this.hourToCron(
            state.Dhours,
            state.hourType
          )} ${this.monthDayDefaultChar} * MON-FRI ${
            this.yearDefaultChar
          }`.trim();
          break;
        default:
          throw new Error("Invalid cron daily subtab selection");
      }
      //console.log(this.cronExpression); 
      this.expressionDescription = cronstrue.toString(this.cronExpression);
      //console.log(this.expressionDescription);
      this.cronExpressionChange.emit(this.cronExpression);
    }

    private computeWeeklyCron(state: any) {
      //console.log(state); 
      const days = this.cronData.weeklyDays
      .reduce(
        (acc: any, day: string, index: number) => (this.selectOptions.days[index] ? acc.concat([day.substring(0,3).toUpperCase()]) : acc),
        []
      );
      this.cronExpression = `${this.isCronFlavorQuartz ? 0 : ""} ${
        state.Wminutes
      } ${this.hourToCron(state.Whours, state.hourType)} ${
        this.monthDayDefaultChar
      } * ${days} ${this.yearDefaultChar}`.trim();

      //console.log(this.cronExpression); 
      this.expressionDescription = cronstrue.toString(this.cronExpression);
      //console.log(this.expressionDescription);
      this.cronExpressionChange.emit(this.cronExpression);
    }

    private computeMonthlyCron(state: any) {
      switch (this.MonthlyselectedDaily) {
        case "OntheDay":
          this.cronExpression = `${
            this.isCronFlavorQuartz ? 0 : ""
          } ${state.Mminutes} ${this.hourToCron(
            state.Mhours,
            state.hourType
          )} ${state.daynum} 1/${state.MonthNum} ${
            this.weekDayDefaultChar
          } ${this.yearDefaultChar}`.trim();
          break;
        case "OnheWeek":
          this.cronExpression = `${
            this.isCronFlavorQuartz ? 0 : ""
          } ${state.Mminutes} ${this.hourToCron(
            state.Mhours,
            state.hourType
          )} ${this.monthDayDefaultChar} 1/${state.MonthNum} ${
            state.DayName
          }${state.monthWeek} ${this.yearDefaultChar}`.trim();
          break;
        default:
          throw new Error("Invalid cron montly subtab selection");
      }

      //console.log(this.cronExpression); 
      this.expressionDescription = cronstrue.toString(this.cronExpression);
      //console.log(this.expressionDescription);
      this.cronExpressionChange.emit(this.cronExpression);
    }

// onCronExpressionChange(newCronExpression: string) {
//   //console.log('newCronExpression');
//   //console.log(newCronExpression);
//     this.cronExpressionChange.emit(newCronExpression);
//   }

}
