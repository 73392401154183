import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterConfig, FilterTypeDefintion } from 'src/app/helpers/app-models';
import { DateTimeUtilsService } from 'src/app/helpers/date-time-utils.service';
import { VDXFilterEventAndDataHanders } from './vdx-report-filters-event-handlers';
import * as appConsts from './helpers/stats-constants';

@Component({
  selector: 'vr-vdx-filters-builder',
  templateUrl: './vdx-filters-builder.component.html',
  styleUrls: ['./vdx-filters-builder.component.scss']
})
export class VdxFiltersBuilderComponent extends VDXFilterEventAndDataHanders implements OnInit {

  _selectAllLabs  = false;
  _filterControlEnumIds = new Array<FilterTypeDefintion>(); // Dates, types, ...etc

  _dataSourceFilters = new Array<FilterTypeDefintion>(); //Laboratory, ordering org..etc
  @Input() set filters(val:Array<FilterTypeDefintion>){
    this._dataSourceFilters = [...val];
    this.isDropdownValuesReady = true;

    console.log("Filter Data");
    console.log(this._dataSourceFilters);
    this._dataSourceFilters.forEach((e) => { console.log(e.filterLabel+'  -- '+e.filterType+' -- ' +e.userValue)});
 
  }
  @Input() isDropdownValuesReady : boolean = false;
  @Input() reportFileType :string = '';
  @Input() options : any = {};
  @Input() inputPayload : any;

  @Output() onDataChagned = new EventEmitter<FilterConfig>();

  constructor(
    private dtUtilsForSuper : DateTimeUtilsService
    ) { 
      super(dtUtilsForSuper);
      this.raiseDataChangedEventFn = this.raiseDataChangedEvent;
    }

  ngOnInit(): void {
    this.config = this.inputPayload; //Links the DB copy with in-memory tracking object
  }

  public consts = appConsts;


  labelTdClass = 'pe-4 fw-bold align-middle';
  controlTdClass = '';

  raiseDataChangedEvent = ()=> {
    //console.log(JSON.stringify(this.config,null,2))
    this.onDataChagned.emit(this.config);
  }

  onClientIdChanged(e:any){
    this.onDataChagned.emit(this.config);
  }

  isDateFilter = (f:FilterTypeDefintion)=> f.filterType.toLowerCase().includes('date') && 
  f.filterType.toLocaleLowerCase() !== this.consts.VDXReportFilterTypes.START_DATE && 
  f.filterType.toLocaleLowerCase() !== this.consts.VDXReportFilterTypes.END_DATE;

  _searchableDropDownBoxFilters : Array<string> = [
    this.consts.VDXReportFilterTypes.CASE_TYPE,
    this.consts.VDXReportFilterTypes.CYTO_TECHNO_TYPE,
    this.consts.VDXReportFilterTypes.PATHOLOGISTS,
    this.consts.VDXReportFilterTypes.DIAGNOSTICIAN, 
    this.consts.VDXReportFilterTypes.SCREENTECHNOLOGIST,
    this.consts.VDXReportFilterTypes.SALESEXECUTIVE, 
    this.consts.VDXReportFilterTypes.FLAGS,
    this.consts.VDXReportFilterTypes.ACCESSIONED_BY,
    this.consts.VDXReportFilterTypes.ACTION_PERFORMED,
    this.consts.VDXReportFilterTypes.PERFORMED_BY,
    this.consts.VDXReportFilterTypes.READING_LOCATION,
    this.consts.VDXReportFilterTypes.CASE_STATUS_LIST ,
    this.consts.VDXReportFilterTypes.HemOnc_SubCaseType,
    this.consts.VDXReportFilterTypes.CASE_STATUS
  ];

  _checkboxFilters: Array<string> = [
    this.consts.VDXReportFilterTypes.INCLUDE_CASE_MANUALLY_SENT_FOR_REVIEW,
  ]

  _simpleDropDownBoxFilters : Array<string> = [
     this.consts.VDXReportFilterTypes.ORDER_BY,
     this.consts.VDXReportFilterTypes.CASE_TYPE_CATEGORY,
     this.consts.VDXReportFilterTypes.SERVCIE_TYPE,
     this.consts.VDXReportFilterTypes.DIAGNOSISPATHOLOGIST,
     this.consts.VDXReportFilterTypes.CATEGORY,
     this.consts.VDXReportFilterTypes.TEST_STATUS,
     this.consts.VDXReportFilterTypes.TEST_TYPE,
     this.consts.VDXReportFilterTypes.TIME_ZONE,
     this.consts.VDXReportFilterTypes.MESSAGE_TYPE,
     this.consts.VDXReportFilterTypes.DIRECTION,
     this.consts.VDXReportFilterTypes.STATUS
  ]

}
