import { Component, ElementRef, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FilterTypeDefintion, RBOrganizationReportsDefinition, RBUserscheduledReportMaster, UIListItem } from 'src/app/helpers/app-models';
import { RbDataService } from 'src/app/services/rb-data.service'; // Adjust the path as needed
import { VdxDropdownsDataManagerService } from '../../vdx-filters-builder/vdx-helpers/vdx-dropdowns-data-manager.service';
import { ReportConfigurationHelper } from './report-configurations-helper';
import { AxiosResponse } from 'axios';
import { ActivatedRoute, Router } from '@angular/router';
import { MsgboxService } from 'src/app/helpers/msgbox.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, DialogData } from 'src/app/shared/confirmation-dialog/confimation-dialog.component';
import { MainAppUtils } from 'src/app/helpers/main-app-utils';
import { MenuItem } from 'primeng/api';
import { AppHelperService } from 'src/app/helpers/app-helper';
import { ToastrService } from "ngx-toastr";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-report-configuration',
  templateUrl: './report-configuration.component.html',
  styleUrls: ['./report-configuration.component.scss'],
})
export class ReportConfigurationComponent implements OnInit {

  reportId: number;
  action: number;
  showloader:boolean=false
  loaderForEntirePage : boolean = false

  _selectedReport: RBOrganizationReportsDefinition = new RBOrganizationReportsDefinition();
  _schedules: Array<RBUserscheduledReportMaster> = new Array<RBUserscheduledReportMaster>();
  _selectedReportForSave: RBOrganizationReportsDefinition = new RBOrganizationReportsDefinition();
  _masterReport: RBOrganizationReportsDefinition = new RBOrganizationReportsDefinition();
  _master_criteria = new Array<UIListItem>();
  _master_masterColumns = new Array<UIListItem>();
  _slave_criteria = new Array<UIListItem>();
  _slave_masterColumns = new Array<UIListItem>();
  _filtersAndData: FilterTypeDefintion[] = new Array<FilterTypeDefintion>();
  _loadSchedules = true;
  _showReportDetails = true;
  isData = false;
  labUserForm: FormGroup;
  isColumnConfigExpanded: boolean = false;
  _deletedScheduleJobIds: Array<number> = new Array<number>();
  utils: MainAppUtils = new MainAppUtils();
  _templateFilesSelectedToUpload: File | undefined;
  items: MenuItem[];
  templateFileUploader: ElementRef | undefined;
  isDirty: boolean = false; 
  showConfirmationPopup: boolean = false;
  scheduleToDelete: { index: number; jobId: number; reportId: number; isAction: string } | null = null;
  showPopup: boolean= false; 

  constructor(private _fb: FormBuilder,
    private vdxDdlData: VdxDropdownsDataManagerService,
    private vdxData: RbDataService,
    private rdb: RbDataService,
    private msgbox: MsgboxService,
    private dialog: MatDialog,
    private activeroute: ActivatedRoute,
    private appHelper: AppHelperService,
    private toastr: ToastrService,
    private router: Router) {
    this.labUserForm = this._fb.group({
      reportName: [''],
      reportDescr:[''],
    });
  }

  onUserInput() {
    this.isDirty = true;
  }

  public _helper = new ReportConfigurationHelper();

  ngOnInit(): void {
    this.activeroute.queryParams.subscribe(params => { 
      this.reportId = +(atob(params['reportId'])); // Use the correct key as passed in the queryParams
      this.action = params['action']; 
      if( this.action == undefined){ 
        this.action = 0;
      }
      else{
        this.action = +(atob(this.action.toString()));
      } 
      this.LoadReportDetails(this.reportId, this.action);
    });
  }

  LoadReportDetails(masterRepId: number, action: number) {
    if (masterRepId > 0) {
      this.rdb.GetSlaveReportsByMasterId(masterRepId, action).then((res) => {
        if (res.data) {
          this._selectedReport = res.data[res.data.length - 1];
          this.items = [
            { label: 'Statistics', routerLink: '/' },
            { label: this._selectedReport.title},
            { label: action == 1 || action == 2 ? 'Edit' : 'Create' }
          ];
          this.labUserForm.patchValue({reportName:this._selectedReport.title})
          this.labUserForm.patchValue({reportDescr:this._selectedReport.description})
          this._selectedReportForSave = { ...this._selectedReport };
          this._selectedReportForSave.action = action == 1 ? "Publish" : action == 2 ? "Draft" : "";

          this._master_criteria = this._helper.convertCsvToUiListItems(
            this._selectedReport.masterCriteriaDisplayName,
            this._selectedReport.masterCriteria
          );

          this._slave_criteria = this._helper.convertCsvToUiListItems(
            this._selectedReport.criteriaDisplayNames,
            this._selectedReport.criteria
          );       
        
          this.publishSlaveFilterData(this._slave_criteria);

          //API -> To get required data for schedular parameters
          this.vdxDdlData.SetData(null);
          this.showloader=true
          this.vdxData.LoadRequiredMasterDataForFilters(
            this._selectedReport.id,
            this._selectedReport.title,
            this._selectedReport.criteria,
            false,
            this._selectedReport.category,
            action
          )
            .then((res) => {
              const apiResponse = res.data; 
              fetch('assets/CriteriaData.json')
              .then((response) => response.json())
              .then((jsonData) => {
                // Merge the data
                const mergedData = {
                  ...apiResponse, // Spread the API response properties
                  ...jsonData, // Spread the additional JSON data
                };
          
                // Set merged data
                this.vdxDdlData.SetData(mergedData);
              })
              .catch((error) => {
                console.error('Error loading JSON data:', error);
              });

              if(action==1 || action==2){
              //API ->  now load all schedular details.
              this.rdb.GetAllScheduledJobs(this._selectedReport.id.toString(), action)
              .then((res) => {
                this.showloader=false
                this._schedules = res.data;
                this.rdb.BuildDatesStoreForUI(this._schedules);
                this._showReportDetails = true;
              });
              }
              else{
                this.showloader=false
              }
            });
        }
      });
    }
  }

  addNewSchedule() {
    let newSchedule = new RBUserscheduledReportMaster();
    newSchedule.isActive = true;
    newSchedule.cronExpression = "0 0 0/1 1/1 * ? *";
    newSchedule.notifyOnlyOnData = false;
    this._schedules.push(newSchedule);
  }

  publishSlaveFilterData(objs: Array<UIListItem>) {
    this._filtersAndData = objs.map((o: UIListItem) => new FilterTypeDefintion(o.name, o.value));
    this.propagateValueToSchedules();
  }

  onReceivedColumnSelection(objs: Array<UIListItem>) {
    this._selectedReportForSave.slavecolumn = this._helper.convertUiListToCsv(
      objs,
      "value"
    );
    this.propagateValueToSchedules();
  }

  propagateValueToSchedules() {
    //want this to be run in synchronouse mode, so for(i) looping and not forEach.
    for (let i = 0; i < this._schedules.length; i++) {
      this._schedules[i].reportName = this._selectedReportForSave.title;
      this._schedules[i].reportColumns = this._selectedReportForSave.slavecolumn;
    }
  }
  
  onReceivedFilterSelection(objs: Array<UIListItem>) {
    this._selectedReportForSave.criteria =
      this._helper.convertUiListToCsv(objs, "value");

    this._selectedReportForSave.criteriaDisplayNames =
      this._helper.convertUiListToCsv(objs, "name");

    //This updates, UI on runtime.
    this.publishSlaveFilterData(objs);
    //this._filtersAndData = objs.map((o:UIListItem)=> new FilterTypeDefintion(o.name,o.value));
  }

  removeSchedule(index: number, jobId: number, reportId: number, isAction: string): void {
    // Store the details of the schedule to be deleted
    this.scheduleToDelete = { index, jobId, reportId, isAction };
    // Open the confirmation popup
    this.showConfirmationPopup = true;
  }

  closeConfirmationPopup(): void {
    // Close the popup without deleting the schedule
    this.showConfirmationPopup = false;
    this.scheduleToDelete = null;
  }

  confirmDeletion(): void {
    if (this.scheduleToDelete) {
      const { index, jobId, reportId, isAction } = this.scheduleToDelete;

      // Proceed with deletion logic
      this._deletedScheduleJobIds.push(this._schedules[index].jobId);
      this._schedules.splice(index, 1);

      // Determine action based on 'isAction'
      let action: number = 0;
      action = isAction === 'Publish' ? 1 : isAction === 'Draft' ? 2 : 0;

      // Call the delete API
      this.rdb.DeleteScheduledJobs(jobId, action).then(
        (response: AxiosResponse<any>) => {
          if (response.status === 200) {
            this.msgbox.error('Schedule Deleted Successfully!', '');
          } else {
            console.log('Schedule deletion failed');
          }
        }
      );

      this.closeConfirmationPopup();
    }
  }

  get userSelectionListArray(): string[] {
    return this._selectedReport.slavecolumn ? this._selectedReport.slavecolumn.split(',') : [];
  }
  
  get userSelectionArray(): string[] {
    return this._selectedReport.slaveCategory ? this._selectedReport.slaveCategory.split(',') : [];
  }

  get masterListArray(): { name: string; category: string }[] {
    const names = this._selectedReport.masterColumns ? this._selectedReport.masterColumns.split(',') : [];
    const categories = this._selectedReport.masterCategory ? this._selectedReport.masterCategory.split(',') : [];
    
    return names.map((name, index) => ({
      name: name.trim(),
      category: categories[index] ? categories[index].trim() : ''
    }));
  }
  
  get slaveListArray(): { name: string; category: string }[] {
    const names = this._selectedReport.slavecolumn ? this._selectedReport.slavecolumn.split(',') : [];
    const categories = this._selectedReport.slaveCategory ? this._selectedReport.slaveCategory.split(',') : [];
    
    return names.map((name, index) => ({
      name: name.trim(), 
      category: categories[index] ? categories[index].trim() : ''
    }));
  }
  
  toggleColumnConfig() {
    this.isColumnConfigExpanded = !this.isColumnConfigExpanded;
  }

  handleuserSelectionListArray(selectedItems: { name: string; category: string }[]) {
    const columns = selectedItems.map(item => item.name).toString();
    const categories = selectedItems.map(item => item.category).toString();

    this._selectedReport.slavecolumn = columns;
    this._selectedReport.slaveCategory = categories; 
    this.isColumnConfigExpanded = !this.isColumnConfigExpanded;
  }
  
  RemoveItem(item: string, event: Event) {
    const regex = new RegExp(`(?:^|,)${item}(?=,|$)`);
    this._selectedReport.slavecolumn = this._selectedReport.slavecolumn.replace(regex, '').replace(/^,|,$/g, '').trim();
    
    if(this.isColumnConfigExpanded){
      this.isColumnConfigExpanded = !this.isColumnConfigExpanded;
      new Promise(() => setTimeout(() => {
        this.isColumnConfigExpanded = !this.isColumnConfigExpanded;
      }, 500));
    }
    event.stopPropagation();
    
  }

  onDownloadTemplate() {
    if(this._selectedReport.templateFile){
      this.rdb.DownloadTemplate(this._selectedReport.templateFile).then((res) => {
        this.utils.StartBrowserDownload(res, this._selectedReport.templateFile);
      });
    }
    else{
      this.msgbox.error("No Template Available", "");
    }
  }

  RemoveFile(): void {
    const confirmRemove = window.confirm(
      'Are you sure you want to remove the template?'
    );
    if (confirmRemove) {
      this._selectedReport.templateFile = ''; 
    }
  }

  onFileSelectedToUpload(e: any) {
    const file = e.target.files[0];
    if (file) {
      const allowedExtensions = ['xls', 'xlsx'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (!allowedExtensions.includes(fileExtension)) {
        this.msgbox.error("Please upload a valid Excel file (.xls or .xlsx)", "");
        e.target.value = '';
        return;
      }
  
      // Check if a template file is already selected
      if (this._selectedReport.templateFile) {
        const userConfirmed = confirm(
          "A template already exists. Are you sure you want to replace it?"
        );
        if (!userConfirmed) {
          e.target.value = ''; // Clear the input field
          return;
        }
      }
      
      this._templateFilesSelectedToUpload = file;
    }
  }
  

  OnReturnClick(): void {
    if (this.isDirty===true) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: {
          title: 'Unsaved Changes',
          message: 'You have unsaved changes. Are you sure you want to leave?',
          confirmButtonText: 'Leave',
          cancelButtonText: 'Stay',
        },
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.router.navigate(['/']); 
        }
      });
    } else {
      this.router.navigate(['/']); // Navigate directly if there are no unsaved changes
    }
  }

  confirmClose(): void {
    this.router.navigate(['/']); 
  }
  
  confirmStay(): void {
    this.showPopup=false;
  }

  IsAllInputValid(): boolean {
    if (this.utils.isEmptyOrSpaces(this.labUserForm.controls['reportName'].value)) {
      this.toastr.error("Please Enter The Report Title.");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this.labUserForm.controls['reportDescr'].value)) {
      this.toastr.error("Please Enter The Report Description.");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this._selectedReportForSave.criteria)) {
      this.toastr.error("Please Select Atleast One Filter Criteria.");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this._selectedReportForSave.roles)) {
      this.toastr.error("Please Select Atleast One Role.");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this.userSelectionListArray.toString()) && this._selectedReport.isMultiSheetEnabled==false) {
      this.toastr.error("Please Select Atleast One Column.");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this._selectedReportForSave.timezone)) {
      this.toastr.error("Please Select Timezone.");
      return false;
    }
    let schedulesToSave = this.utils.clone(this._schedules);
    this.rdb.BuildDatesStoreForScheduler(this._selectedReport, schedulesToSave);

    if (schedulesToSave.length > 0) {
      for (let i = 0; i < schedulesToSave.length; i++) {
        if (schedulesToSave[i].isMailRequired ==true && this.utils.isEmptyOrSpaces(schedulesToSave[i].emailTo)) {
          this.toastr.error(`Please fill in the "Email To" field for Schedule ${i + 1}`);
          return false;
        }
        if (schedulesToSave[i].isMailRequired ==true && this.utils.isEmptyOrSpaces(schedulesToSave[i].emailSubject)) {
          this.toastr.error(`Please enter the Email Subject for Schedule ${i + 1}`);
          return false;
        }
        if (schedulesToSave[i].reportLockPasswordType === "custom" && (!schedulesToSave[i].reportLockPasswordData || schedulesToSave[i].reportLockPasswordData.trim() === "")) {
          this.toastr.error(`Please enter a Custom Password for Schedule ${i + 1}`);
          return false;
        }        
      }
    }
    return true;
  }
  
  Save(actionNumber: number, isDraft: boolean = false): void {
    if (this.IsAllInputValid()) {
      this.loaderForEntirePage = true
      try {
      this.rdb.ValidateReportTitle(this.labUserForm.controls['reportName'].value, this._selectedReportForSave.id)
        .then((res) => {
          if (res.data === 0) {
            if (this._templateFilesSelectedToUpload) {
              this._selectedReportForSave.templateFile = this._templateFilesSelectedToUpload.name;
            
              this.rdb.UploadTemplate(this._templateFilesSelectedToUpload)
                .then((uploadRes) => {
                  // Check if the upload was successful based on the returned boolean 'success'
                  if (!uploadRes==true) {
                    this.toastr.error("Template file upload failed");
                    return;
                  }            
                  // Clear the file input if the upload was successful
                  if (this.templateFileUploader) {
                    this.templateFileUploader.nativeElement.value = "";
                  }
                })
                .catch((err) => this.appHelper.HandleError(err));
              }
              else{
                this._selectedReportForSave.templateFile ='';
              }           
  
            this._selectedReportForSave.title = this.labUserForm.controls['reportName'].value;
            this._selectedReportForSave.description = this.labUserForm.controls['reportDescr'].value;
            this._selectedReportForSave.slavecolumn = this.userSelectionListArray.toString();
            this._selectedReportForSave.slaveCategory = this.userSelectionArray.toString();
            this._selectedReportForSave.isMultiSheetEnabled = this._selectedReport.isMultiSheetEnabled
            this._selectedReportForSave.action = actionNumber.toString();
            this._selectedReportForSave.isPublish = (actionNumber === 1);
  
            let notifyBlankAttachment = this._selectedReportForSave.NotifyBlankAttachment;
            let notifyOnlyOnData = this._selectedReportForSave.notifyOnlyOnData;
  
            this.rdb.UpdateSlaveReport(this._selectedReportForSave)
              .then((updateRes) => {
                if (updateRes.data) {
                  this._selectedReport = updateRes.data[0];
                  this._selectedReport.action = this._selectedReportForSave.action;
                  this._selectedReport.notifyOnlyOnData = notifyOnlyOnData;
                  this._selectedReport.NotifyBlankAttachment = notifyBlankAttachment;
  
                  let schedulesToSave = this.utils.clone(this._schedules);
                  this.rdb.BuildDatesStoreForScheduler(this._selectedReport, schedulesToSave);
  
                  this.rdb.UpdateSchedules(schedulesToSave)
                    .then((scheduleRes) => {
                      if (scheduleRes.data) {
                        let savedEntries: RBUserscheduledReportMaster[] = scheduleRes.data;
  
                        if (this._deletedScheduleJobIds.length > 0) {
                          this.rdb.RemoveScheduledJobs(this._deletedScheduleJobIds.join(","));
                        }
  
                        this._schedules = this.rdb.BuildDatesStoreForUI(savedEntries);
                        this.toastr.success("Saved successfully!");
                        
                        // If isDraft is true, publish the report
                        if (isDraft) {
                          this.rdb.PublishReport(this._selectedReportForSave.id)
                            .then((publishRes) => {
                              if (publishRes.data === true) {
                                //this.msgbox.success("Report Published Successfully", "");
                                console.log("Report Published Successfully");
                              } else {
                                //this.msgbox.error("Report Publish Failed", "");
                                console.log("Report Publish Failed");
                              }
                            })
                            //.catch((err) => this.appHelper.HandleError(err));
                        }
  
                        this.loaderForEntirePage = false
                        this.router.navigate(['/']);
                      }
                    })
                    .catch((err) =>{
                      this.loaderForEntirePage = false
                      this.appHelper.HandleError(err)
                    });
                }
              });
          } else {
            this.loaderForEntirePage = false
            this.toastr.error("Report title already exists in this Organization.");
          }          
        })
        .catch((err) => this.appHelper.HandleError(err));
      } catch (error) {
        this.loaderForEntirePage = false
      }
    }
  } 

}
