import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { UserDetails } from "./app-models";
import { BrowserStorageManagerService } from "./browser-storage-manager.service";


@Injectable({
  providedIn: "root",
})
export class ConfigUtils {
  private userObject: UserDetails;

  constructor(private emmSvc: BrowserStorageManagerService) {
    this.userObject = emmSvc.GetUserInfo();
  }

  private GetApiBaseUrl(){
    return ;
  }

  public BuildApiUrl(apiMethod:string){
    return `${environment.apiUrlBase}/api/ReportListing/${apiMethod}`
  } 

  public GetAppApiHeaders() {
     
    return environment.production ? this.emmSvc.GetAPIHeaders() : environment.dev_auth;
  }

  public GetConnectionName(){
    return "ReportDB";
  }

  public GetLoggedUserEmail(){
    return environment.production ? this.userObject.loginid : "devtest@kk.com";
  }

  // public GetOrgGUID() {
  //   return environment.production
  //     ? this.userObject.userorg
  //     : environment.dev_org_guid;
  // }

  // public GetUserGUID() {
  //   return environment.production
  //     ? this.userObject.userguid
  //     : environment.dev_user_guid;
  // }

  // public GetUserId() {
  //   return environment.production
  //     ? this.userObject.userid
  //     : environment.dev_user_id;
  // }

  public GetLoggedInOrganizationName() {
    return environment.production
      ? this.userObject.OrganizationName
      : "Specimen Laboratory";
  }

}
