import { Component } from '@angular/core';
import { SsoEmmAuthService } from './helpers/sso-emm-auth.service';
import { MsgboxService } from './helpers/msgbox.service';
import { idleService } from './services/idle-service';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'RB-UI';
  BiginwithSession = false; 
  orgid: string='';
  UserID: string =''
  deploymentKeys:string='';
  EmailID:string='';
  AccountID:string='';
  ProductName:string ='';
 // http: HttpClient = new HttpClient();
  
  constructor(
    private primengConfig : PrimeNGConfig,
    private ssoEmm: SsoEmmAuthService,
    private msgbox: MsgboxService,
    private idleService:idleService,
     private route: ActivatedRoute,
     private router: Router
  ){}

  ngOnInit(): void {
       this.route.queryParams.subscribe(params => {
       console.log(this.router.url);
      console.log('Report ID:', params['reportId']);
    });
    this.primengConfig.ripple = true;
     
     if (!environment.production)
      {
        this.ssoEmm.CheckSession().then(res => {
          console.log(res); 
          if(res.data.message == "NoSessions"){
              this.BiginwithSession = true;
            }
            else{
              this.BiginwithSession = false;
            }
        })
        
      }
      else
      {
        this.BiginwithSession = false;
      }
    //   this.ssoEmm.validateUser();
  }

  GenerateSession(ORGID:string, DeploymentKey:string, emailID : string,AccountID:string, UserID:string,ProductName:string){
    this.ssoEmm.SetSession(ORGID, DeploymentKey,emailID,AccountID,UserID,ProductName).then((res) => { 
      console.log(res); 
      document.location.href = res.data;     
      // if(res.data.message == "NoSessions")
      //   {
      //     this.msgbox.error("Error", "No active sessions found, Please relaunch the application."); 
      //     console.log(res);
      //   }
      //   else{
      //     this.msgbox.success("Success!!!", "Session setting ompleted successfully."); 
      //     this.BiginwithSession = false;
      //     console.log(res);
      //   }
    });;
    
  } 
}
