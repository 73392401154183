<div *ngIf="_dataSourceFilters.length > 0">
  
    <rb-config-panel *ngFor="let f of _dataSourceFilters"
      [header]="f.filterLabel"
      cssStyle="w-100 mb-2"
    >
      <rb-dates-config
        *ngIf="isDateFilter(f)"
        key="filter"
        [value]="f.userValue"
        (onValuesChanges)="onFilterDateValueChange($event,f.filterType.toLowerCase())"
      ></rb-dates-config>
  

    <vr-vdx-bulk-laboratory-selector
      *ngIf="f.filterType.toLowerCase() === consts.VDXReportFilterTypes.LABORATORY"
      [label]="f.filterLabel"
      [SelectedValues]="f.userValue"
      [isDropdownValuesReady]="isDropdownValuesReady" 
      (onFilterValueChange)="onFilterValueChange($event,f.filterType.toLowerCase())"
      (onSelectedLabelsChange)="onFilterValueChange($event,f.filterType.toLowerCase()+'Names')"
    ></vr-vdx-bulk-laboratory-selector>


    <div
      class="d-flex flex-column border rounded bg-light"
      *ngIf="f.filterType.toLowerCase() === consts.VDXReportFilterTypes.ORDERING_ORGANIZATION">
      <div>
        <vr-vdx-bulk-organization-selector
          [label]="f.filterLabel"
          [selctionType]="options.orderingorg_selection"
          [SelectedValues]="f.userValue"
          [isDropdownValuesReady]="isDropdownValuesReady"
          (onFilterValueChange)="onFilterValueChange($event,f.filterType.toLowerCase())"
          (onSelectedLabelsChange)="onFilterValueChange($event,f.filterType.toLowerCase()+'Names')"
        >
        </vr-vdx-bulk-organization-selector>
      </div>
    </div>

    <div *ngIf="f.filterType.toLowerCase() === consts.VDXReportFilterTypes.PERCENTAGE">
      <label>{{f.filterLabel}}:&nbsp;&nbsp;&nbsp;</label>
      <app-numberic-input-control
        [min]="0"
        [max]="100"
        [initialValue]="f.userValue"
        (onChange)="onFilterNumberValueChange($event,consts.VDXReportFilterTypes.PERCENTAGE)"
      ></app-numberic-input-control>
    </div>

    <div *ngIf="f.filterType.toLowerCase() === consts.VDXReportFilterTypes.INCLUDE_CASE_MANUALLY_SENT_FOR_REVIEW">
      <app-filter-checkbox-control-component
      [label]="f.filterLabel"
      [checked]="(f.userValue ?? false).toString()"
      (onCheckToggled)="onFilterValueChangeBoolean($event, f.filterType)">
    </app-filter-checkbox-control-component>    
    </div>

    <app-vdx-algorithm-options
      *ngIf="f.filterType.toLowerCase() === consts.VDXReportFilterTypes.ALGORITHM_TYPE"
      [label]="f.filterLabel"
      [selected]="f.userValue"
      (onValueSelected)="onFilterValueChange($event,consts.VDXReportFilterTypes.ALGORITHM_TYPE)"
    ></app-vdx-algorithm-options>

    <vr-vdx-generic-object-dropdown
      *ngIf="_searchableDropDownBoxFilters.indexOf(f.filterType.toLowerCase()) > -1"
      [label]="f.filterLabel"
      [initValuesCsv]="f.userValue"
      labelCss="fw-bold"
      [filterType]="f.filterType.toLowerCase()"
      (onSelected)="onFilterValueChange($event, f.filterType.toLowerCase())"
    ></vr-vdx-generic-object-dropdown> 

    <div *ngIf="_simpleDropDownBoxFilters.indexOf(f.filterType.toLowerCase()) > -1">
      <vr-vdx-generic-simple-dropdown
        [filterType]="f.filterType.toLowerCase()"
        [selectedValue]="f.userValue"
        (onSelected)="onFilterValueChange($event,f.filterType.toLowerCase())"
      ></vr-vdx-generic-simple-dropdown>
    </div> 
  </rb-config-panel>
</div>
