<div
  class="d-flex flex-row justify-content-start align-items-center gap-2 cursor-pointer"
  (click)="onReceivedCheckedChange()"
>
  <div style="line-height: 0 !important;">
    <input
      style="transform: scale(1.3) !important;"
      type="checkbox"
      [checked]="_checked"
    />
  </div>
  <div
  class="check-ui fw-bold"  
  [title]="label"
  >
    {{label}}
  </div>
</div>
