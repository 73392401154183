import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RBUserscheduledReportMaster } from 'src/app/helpers/app-models';
import { MainAppUtils } from 'src/app/helpers/main-app-utils';

@Component({
  selector: 'app-email-config',
  templateUrl: './email-config.component.html',
  styleUrls: ['./email-config.component.scss']
})
export class EmailConfigComponent {

  loader:true;
  labUserForm: FormGroup;
  _showCC: boolean = false;
  _showBCC: boolean = false;
  _mainUtils = new MainAppUtils();
  _selectedSchdule = new RBUserscheduledReportMaster();
  value="PDX_{MMDDYY}"

  EmailSubject = new FormControl();
  EmailBody = new FormControl();
  CustomPassword = new FormControl()
  selectedType: string ;
  enablePasswordForCustom: boolean = false;
  enablePasswordForDefault: boolean = false;

  constructor(private _fb: FormBuilder,private activeroute: ActivatedRoute,) {
    this.labUserForm = this._fb.group({
      emailId: ['', [Validators.pattern(/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/)]],
      subject: ['',[Validators.required, Validators.minLength(1)]],
      description: ['',[Validators.required, Validators.minLength(1)]],
      cc:[''],
      bcc:[Validators.required, Validators.minLength(1)],
      password:[]
    });
  }
  ngOnInit(): void {
    this.EmailBody.patchValue(this._selectedSchdule.emailBody);
    this.EmailSubject.patchValue(this._selectedSchdule.emailSubject);
    this.selectedType = this._selectedSchdule.reportLockPasswordType || "dynamic";

  
    // Check selectedType and set corresponding password enable flag
    if (this.selectedType === 'custom') {
      this.enablePasswordForCustom = this._selectedSchdule.enablePasswordToEmail;
    } else if(this.selectedType === 'dynamic'){
      this._selectedSchdule.reportLockPasswordType = 'dynamic';
      this._selectedSchdule.reportLockPasswordData = '';
    }
    else {
      this.enablePasswordForDefault = this._selectedSchdule.enablePasswordToEmail;
    }
  
    this.CustomPassword.patchValue(this._selectedSchdule.reportLockPasswordData);
    this.activeroute.queryParams.subscribe(params => {
      const actionParam = params['action'] ? atob(params['action']) : null;
      const action = actionParam ? +actionParam : null;
      if (action !== 1 && action !== 2) {
        this.selectedType = 'dynamic';
      }
    });
  }
  
  @Input() set selectedSchdule(val:RBUserscheduledReportMaster){
    this._selectedSchdule = val;
    this._showBCC = !this._mainUtils.isEmptyOrSpaces(this._selectedSchdule.emailBCC)
    this._showCC = !this._mainUtils.isEmptyOrSpaces(this._selectedSchdule.emailBCC)

    this.labUserForm.patchValue({emailId:this._selectedSchdule.emailBCC})
    this.labUserForm.patchValue({reportDescr:this._selectedSchdule.emailBCC})
    this.labUserForm.patchValue({subject:this._selectedSchdule.emailSubject})
    this.labUserForm.patchValue({reportDescr:this._selectedSchdule.emailBCC})
  }

  selectType(type: string) {
    this.selectedType = type;

    if (type === "custom") {
      this.enablePasswordForDefault = false;
      this.enablePasswordForCustom = false;
      this.CustomPassword.reset(); 
      this._selectedSchdule.reportLockPasswordType = type;
    } else if (type === "default") {
      this.enablePasswordForCustom = false;
      this.enablePasswordForDefault = false;
      this.CustomPassword.reset(); 
      this._selectedSchdule.reportLockPasswordType = type;
      this._selectedSchdule.reportLockPasswordData="PDX_{MMDDYY}";
    }else if(type === "dynamic"){
      this._selectedSchdule.reportLockPasswordType = type;
      this._selectedSchdule.reportLockPasswordData='';
    }
    else{
      this._selectedSchdule.reportLockPasswordType = type;
      this._selectedSchdule.reportLockPasswordData='';
    }
  }

  showCC() {
    this._showCC = !this._showCC;
  }

  showBCC() {
    this._showBCC = !this._showBCC;
  }

  PatchSubject(val:any)
  {
    this._selectedSchdule.emailSubject=val
  }
  PatchBody(val:any)
  {
    this._selectedSchdule.emailBody=val
  }
  PatchPassword(val:any)
  {
    this._selectedSchdule.reportLockPasswordData=val;
    this._selectedSchdule.enablePasswordToEmail= this.enablePasswordForCustom;
  }

  updatePasswordForCustom() {
    this._selectedSchdule.enablePasswordToEmail = this.enablePasswordForCustom;
  }
  
  updatePasswordForDefault() {
    this._selectedSchdule.enablePasswordToEmail = this.enablePasswordForDefault;
  }

}
