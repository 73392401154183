import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { DateTimeUtilsService } from 'src/app/helpers/date-time-utils.service';
import * as consts from "src/app/helpers/app-consts";

@Component({
  selector: 'rb-dates-config',
  templateUrl: './dates-config.component.html',
  styleUrls: ['./dates-config.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatesConfigComponent {

  constructor(private dateUtils: DateTimeUtilsService) {}

  @Input() key: string = "key";

  _val: any = {};
  @Input() set value(val: any) {
    if (val !== null && val !== undefined) {
      this._val = val;
      const jsonObject = JSON.parse(val);
     // console.log(jsonObject.dateTypeCriteria);
      this._days = jsonObject.periodLength || 0; // Default to 0 if not provided
      this._periodSelected = jsonObject.period || "Select"; // Default to "Select" if not provided
      this._startTime = new Date(jsonObject.startTime);
      this._endTime = new Date(jsonObject.endTime);
      this.updateMinMaxValues();
      this.onReceivedValueChange();
    } else {
      this._days = 0;
      this._periodSelected = "Select";
      this.updateMinMaxValues(); // Update min-max values for default period selection
      this.onReceivedValueChange(); // Emit default values
    }
  }

  @Output() onValuesChanges = new EventEmitter<any>();

  _days: number = 0;
  _startTime: Date = new Date();
  _endTime: Date = new Date();
  _isOnBusinessDaysOnly: boolean = false;
  _periods = [ "Days", "Weeks", "Months"];
  _periodSelected = "Days";
  _min: number = 0;
  _max: number = 0;

  updateMinMaxValues() {
    if (this._periodSelected === "Days") {
      this._min = 0;
      this._max = 365;
    } else if (this._periodSelected === "Weeks") {
      this._min = 0;
      this._max = 52;
    } else if (this._periodSelected === "Months") {
      this._min = 0;
      this._max = 12;
    } else {
      this._min = 0;
      this._max = 365; // Default or invalid period
    }
  }

  onPeriodChange(period: string) {
    this._periodSelected = period;
    this.updateMinMaxValues();
    // Ensure the days value is within the new min and max range
    if (this._days < this._min) {
      this._days = this._min;
    } else if (this._days > this._max) {
      this._days = this._max;
    }
    this.onReceivedValueChange();
  }

  validateDaysInput(event: any) {
    let value = parseInt(event.target.value, 10);
    if (isNaN(value)) {
      this._days = this._min; // Reset to minimum if the input is not a number
    } else if (value < this._min) {
      this._days = this._min; // Ensure it's not below minimum
    } else if (value > this._max) {
      this._days = this._max; // Ensure it's not above maximum
    } else {
      this._days = value;
    }

    // Directly update the input value to reflect valid range
    event.target.value = this._days.toString();
    this.onReceivedValueChange();
  }

  onReceivedValueChange() {
    let obj = {
      dateTypeCriteria: "",
      period: this._periodSelected,
      periodLength: this._days,
      startTime: this.dateUtils.FormatDateAsString(
        this._startTime,
        consts.DATETIME_VIEW_SAVE_FORMAT
      ),
      // OnBusinessDaysOnly: this._isOnBusinessDaysOnly,
    };

    this.onValuesChanges.emit(obj);
  }
}
