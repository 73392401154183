import { environment } from "src/environments/environment";

export enum ReportFileType {
    PDF = 'PDF',
    XL = 'XLSX'
}

export const DATE_FORMAT_INPUT = 'yyyy-MM-dd'
export const DATE_FORMAT_SAVE_INPUT = 'MM/dd/yyy'
export const DATETIME_VIEW_FORMAT = 'MM/dd/yyy h:mm:ss a'

export const NO_CRITERIA_INFO_MESSAGE = "Please select report filter criteria(s)"
export const BUTTON_DOWNLOAD_TITLE = "Download Report";
export const MESSAGE_DOWNLOAD_COMPLETE ='DOWNLOAD_DONE';
export const MESSAGE_STOP_ASYNC_CHECK ='MESSAGE_STOP_ASYNC_CHECK';
export const MESSAGE_FAVORITE_UPDATED = 'FAV_STATUS_UPDATED';

export enum CustomReports {
    FAVORITES = 'Favorites',
    RECENT_REPORTS = 'Recent Reports'
}

export const QAI_ADDRESS = 'QAI'

export enum VDXReportFilterTypes {
    DATES_SIGNOUT = 'signoutdate',
    DATES_COLLECTION = 'collectiondate',
    DATES_RECEIVED = 'receiveddate',
    SERVCIE_TYPE = 'servicetype',
    CYTO_TECHNO_TYPE = 'cytotechnologist',
    LABORATORY = 'laboratory',
    ORDERING_ORGANIZATION = 'orderingorganization',
    SALESEXECUTIVE = 'salesexecutive',
    CASE_TYPE = "casetype",
    PERCENTAGE = "percentage",
    PATHOLOGISTS = "pathologist",
    DIAGNOSTICIAN= 'diagnostician',
    SCREENTECHNOLOGIST= "screeningtechnologist",
    FLAGS= "flags",
    ACCESSIONED_BY = 'accessionedby',
    ACTION_PERFORMED = 'actionperformed',
    PERFORMED_BY = 'performedby',
    ALGORITHM_TYPE = 'algorithmtype',
    START_DATE = 'startdate',
    END_DATE = 'enddate',   
    CASE_TYPE_CATEGORY = 'casetypecategory',
    DIAGNOSISPATHOLOGIST = 'diagnosispathologist',
    PROFESSIONAL_STATES = 'professionalstates',
    REPORTED_BY = 'reportby',
    ORDER_BY = 'orderby',
    READING_LOCATION = 'readinglocation',
    CASE_STATUS_LIST = "casestatuslist",
    CATEGORY="category",
    HemOnc_SubCaseType="hemoncsubcasetype",
    TEST_STATUS="teststatus" ,
    TEST_TYPE="testtype",
    TIME_ZONE="timezone",
    CASE_STATUS="casestatus",
    MESSAGE_TYPE="messagetype",
    DIRECTION="direction",
    STATUS="status",
    INCLUDE_CASE_MANUALLY_SENT_FOR_REVIEW = 'includecasesmanuallysentforreview',
}

export enum VDXPayloadKeys {
   // UserId="UserId",
    ReportId="ReportId",
    OrganizationID="OrganizationID",
    Laboratory="Laboratory",
    RunBy="RunBy",
    LaboratoryList="LaboratoryList",
    OrderingOrganizationList="OrderingOrganizationList",
    OrderingFacilityList="OrderingFacilityList",
    ServiceType="ServiceType",
    CaseTypeCategory="CaseTypeCategory",
    SelectedCriterias="SelectedCriterias",
    HemOncSubCaseType="HemOncSubCaseType",
    TestStatus="TestStatus",
    TestType="TestType",
    AccessionNumberFrom="AccessionNumberFrom",
    AccessionNumberTo="AccessionNumberTo",
    CaseTypeList="CaseTypeList",
    CaseStatusList="CaseStatusList",
    ScreenTechnologist="ScreenTechnologist",
    DiagnosisPathologist="DiagnosisPathologist",
    Flags="Flags",
    StoredProcedure="StoredProcedure",
    Criteria="Criteria",
    TemplateFile="TemplateFile",
    NamedRange="NamedRange",
    Category="Category",
    Title="Title",
    ReportType="ReportType",
    version="version",
    _paramsToExcel="_paramsToExcel",
    actualRepName="actualRepName",
    AttributesName="AttributesName",
    PerformedBy="PerformedBy",
    ActionPerformed="ActionPerformed",
    Diagnostician="Diagnostician",
    CustomServicetype="CustomServicetype",
    Algorithmtype="Algorithmtype",
    Percentage="Percentage",
    ReadingLocations="ReadingLocations",
    TimeZone="TimeZone",
    Casetypes="Casetypes",
    DeploymentKey="DeploymentKey",
    Dates="Dates"
}

export const ReportBuilderToStatsCriteriaMap = new Map<string,string>([
    ["ReceivedDateFrom",VDXReportFilterTypes.DATES_RECEIVED],
    ["ReceivedDateTo",VDXReportFilterTypes.DATES_RECEIVED],
    ["CollectionDateFrom",VDXReportFilterTypes.DATES_COLLECTION],
    ["CollectionDateTo",VDXReportFilterTypes.DATES_COLLECTION],
    ["SignoutDateFrom",VDXReportFilterTypes.DATES_SIGNOUT],
    ["SignoutDateTo",VDXReportFilterTypes.DATES_SIGNOUT],
    ["LaboratoryList",VDXReportFilterTypes.LABORATORY],
    ["OrderingOrganization",VDXReportFilterTypes.ORDERING_ORGANIZATION],
    ["Salesexecutive",VDXReportFilterTypes.SALESEXECUTIVE],
    ["ServiceType",VDXReportFilterTypes.SERVCIE_TYPE],
    ["CaseTypeCategory",VDXReportFilterTypes.CASE_TYPE_CATEGORY],
    ["ScreeningTechnologist",VDXReportFilterTypes.SCREENTECHNOLOGIST],
    ["DiagnosisPathologist",VDXReportFilterTypes.DIAGNOSISPATHOLOGIST],
    ["CaseFlags",VDXReportFilterTypes.FLAGS],
    
    ["AccessionNumberFrom","AccessionNumberFrom"],
    ["AccessionNumberTo","AccessionNumberTo"],
    //["UserID","UserID"],

    ["OrderingFacility","OrderingFacility"],
    ["HemOncSubCaseType","hemoncsubcasetype"],
    ["TestStatus","TestStatus"],
    ["TestType","TestType"],
    ["CaseStatusList",VDXReportFilterTypes.CASE_STATUS_LIST],
    
    ["DeletedSequesteredDateFrom","DeletedSequesteredDate"],
    ["DeletedSequesteredDateTo","DeletedSequesteredDate"],
    ["OrderedDateFrom","OrderedDate"],
    ["OrderedDateTo","OrderedDate"],
]);