import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-checkbox-control-component',
  templateUrl: './filter-checkbox-control-component.component.html',
  styleUrls: ['./filter-checkbox-control-component.component.scss']
})
export class FilterCheckboxControlComponentComponent implements OnInit {

  constructor() {}

  public _checked: boolean = false;

  @Input() set checked(val: string) {
    // Convert the string value ('true' or 'false') to a boolean
    this._checked = val === 'true'; // this works if the input value is either 'true' or 'false'
    if (this._checked) {
      this.onCheckToggled.emit(true); // emit the event if checked is true
    }
  }
  @Input() label :string = '';
  @Output() onCheckToggled = new EventEmitter<boolean>();

  ngOnInit(): void {

  }

  onReceivedCheckedChange() {
    this._checked = !this._checked;
    this.onCheckToggled.emit(this._checked);
  }
}
