<div *ngIf="hasSchedules(); else noSchedules">
  <!-- Hourly Schedules -->
  <div *ngIf="schedulesHourly.length > 0" style="padding-left: 20px; padding-top: 10px; padding-right: 20px;">
    <div class="row">
     <div class="col-sm-6 mb-3" style="font-size: 16px;"> Hourly Schedules </div>
    </div>
    <div class="row">
      <div *ngFor="let schedule of schedulesHourly; let i = index" class="col-sm-6 mb-3">
        <div class="card" style="width: 100%;">
          <div class="card-header">
            {{ getTitle(schedule.cron) }}
            <button *ngIf="userDetials.isPublish" 
                    (click)="Previewreport(schedule.jobID)" class="preview-btn">
              Preview
            </button>
          </div>          
          <div class="card-body">
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email To</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
                [matTooltip]="getFormattedEmailTooltip(schedule?.emailTo)" 
                matTooltipPosition="above" 
                matTooltipClass="multiline-tooltip">
                {{ schedule?.['emailToCount'] }} Participants
              </strong>
            </div>          
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email CC</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
              [matTooltip]="getFormattedEmailTooltip(schedule?.emailCC)" 
              matTooltipPosition="above" 
              matTooltipClass="multiline-tooltip">
              {{ schedule?.['emailCCCount'] }} Participants
              </strong>
            </div>
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email BCC</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
              [matTooltip]="getFormattedEmailTooltip(schedule?.emailBCC)" 
              matTooltipPosition="above" 
              matTooltipClass="multiline-tooltip">
              {{ schedule?.['emailBCCCount'] }} Participants
              </strong>
            </div>

            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Password</span>
              <span class="col-sm-1">:</span>
              <div class="col-sm-5 d-flex align-items-center">
                <ng-container *ngIf="schedule?.['passwordType']; else noPassword">
                  <strong class="pl-0">{{ schedule?.['password'] }}</strong>
                  
                  <!-- Conditional Copy Button -->
                  <div class="copy-container position-relative" *ngIf="schedule?.['passwordType'] === 'custom'">
                    <img
                      src="\assets\Icons\Copy.svg"
                      alt="Copy"
                      (click)="copyToClipboard(schedule?.['password'], schedule)"
                      style="cursor: pointer; width: 24px; height: 18px;">
                    <span [ngClass]="{'copy-feedback': true, 'show': schedule.copySuccess}">Copied!</span>
                  </div>  
                  <div *ngIf="schedule?.['passwordType'] === 'dynamic'">
                    <strong class="pl-0 text-muted">Dynamic password</strong>
                  </div>              
                </ng-container>
                
                <ng-template #noPassword>
                  <strong class="pl-0 text-muted">No password</strong>
                </ng-template>
              </div>         
            </div>

            <div class="row d-flex align-items-center" title="Status">
              <span class="col-sm-6 text-muted">Notify Blank Attachment&nbsp;</span>
              <span class="col-sm-1">:</span>
              <app-checkbox-toggle class="col-sm-5"
                [checked]="schedule.notifyBlacnkAttahcment"
                (click)="onToggleClick($event)"
                (onClick)="onToggleNotifyBlank($event, schedule.jobID)"
              ></app-checkbox-toggle>
            </div>
            <div class="row d-flex align-items-center" title="Status">
              <span class="col-sm-6 text-muted">Email report when data is available  &nbsp;</span>
              <span class="col-sm-1">:</span>
              <app-checkbox-toggle class="col-sm-5"
                [checked]="schedule.notifyOnlyOnData"
                (click)="onToggleClick($event)"
                (onClick)="onToggle($event, schedule.jobID)"
              ></app-checkbox-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End of hourly Schedules -->

  
  <!-- Daily Schedules -->
  <div *ngIf="schedulesDaily.length > 0" style="padding-left: 20px; padding-top: 10px; padding-right: 20px;">
    <div class="row">
     <div class="col-sm-6 mb-3" style="font-size: 16px;"> Daily Schedules </div>
    </div>
    <div class="row">
      <div *ngFor="let schedule of schedulesDaily; let i = index" class="col-sm-6 mb-3">
        <div class="card" style="width: 100%;">
          <div class="card-header">
            {{ getTitle(schedule.cron) }}
            <button *ngIf="userDetials.isPublish" 
                    (click)="Previewreport(schedule.jobID)" class="preview-btn">
              Preview
            </button>
          </div> 
          <div class="card-body">
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email To</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
                [matTooltip]="getFormattedEmailTooltip(schedule?.emailTo)" 
                matTooltipPosition="above" 
                matTooltipClass="multiline-tooltip">
                {{ schedule?.['emailToCount'] }} Participants
              </strong>
            </div>
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email CC</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
              [matTooltip]="getFormattedEmailTooltip(schedule?.emailCC)" 
              matTooltipPosition="above" 
              matTooltipClass="multiline-tooltip">
              {{ schedule?.['emailCCCount'] }} Participants
              </strong>
            </div>
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email BCC</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
              [matTooltip]="getFormattedEmailTooltip(schedule?.emailBCC)" 
              matTooltipPosition="above" 
              matTooltipClass="multiline-tooltip">
              {{ schedule?.['emailBCCCount'] }} Participants
              </strong>
            </div>

            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Password</span>
              <span class="col-sm-1">:</span>
              <div class="col-sm-5 d-flex align-items-center">
                <ng-container *ngIf="schedule?.['password']; else noPassword">
                  <strong class="pl-0">{{ schedule?.['password'] }}</strong>
                  
                  <!-- Conditional Copy Button -->
                  <div class="copy-container position-relative" *ngIf="schedule?.['passwordType'] === 'custom'">
                    <img
                      src="\assets\Icons\Copy.svg"
                      alt="Copy"
                      (click)="copyToClipboard(schedule?.['password'], schedule)"
                      style="cursor: pointer; width: 24px; height: 18px;">
                    <span [ngClass]="{'copy-feedback': true, 'show': schedule.copySuccess}">Copied!</span>
                  </div>                
                </ng-container>
                
                <ng-template #noPassword>
                  <strong class="pl-0 text-muted">Dynamic password</strong>
                </ng-template>
              </div>         
            </div>

            <div class="row d-flex align-items-center" title="Status">
              <span class="col-sm-6 text-muted">Notify Blank Attachment&nbsp;</span>
              <span class="col-sm-1">:</span>
              <app-checkbox-toggle class="col-sm-5"
                [checked]="schedule.notifyBlacnkAttahcment"
                (click)="onToggleClick($event)"
                (onClick)="onToggleNotifyBlank($event, schedule.jobID)"
              ></app-checkbox-toggle>
            </div>
            <div class="row d-flex align-items-center" title="Status">
              <span class="col-sm-6 text-muted">Email when data is available  &nbsp;</span>
              <span class="col-sm-1">:</span>
              <app-checkbox-toggle class="col-sm-5"
                [checked]="schedule.notifyOnlyOnData"
                (click)="onToggleClick($event)"
                (onClick)="onToggle($event, schedule.jobID)"
              ></app-checkbox-toggle>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <!-- End of Daily Schedules -->


  <!-- Weekly Schedules -->
  <div *ngIf="schedulesWeekly.length > 0" style="padding-left: 20px; padding-top: 10px; padding-right: 20px;">
    <div class="row">
     <div class="col-sm-6 mb-3" style="font-size: 16px;"> Weekly Schedules </div>
    </div>
    <div class="row">
      <div *ngFor="let schedule of schedulesWeekly; let i = index" class="col-sm-6 mb-3">
        <div class="card" style="width: 100%;">
          <div class="card-header">
            {{ getTitle(schedule.cron) }}
            <button *ngIf="userDetials.isPublish" 
                    (click)="Previewreport(schedule.jobID)" class="preview-btn">
              Preview
            </button>
          </div> 
          <div class="card-body">
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email To</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
                [matTooltip]="getFormattedEmailTooltip(schedule?.emailTo)" 
                matTooltipPosition="above" 
                matTooltipClass="multiline-tooltip">
                {{ schedule?.['emailToCount'] }} Participants
              </strong>
            </div>
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email CC</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
              [matTooltip]="getFormattedEmailTooltip(schedule?.emailCC)" 
              matTooltipPosition="above" 
              matTooltipClass="multiline-tooltip">
              {{ schedule?.['emailCCCount'] }} Participants
              </strong>
            </div>
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email BCC</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
              [matTooltip]="getFormattedEmailTooltip(schedule?.emailBCC)" 
              matTooltipPosition="above" 
              matTooltipClass="multiline-tooltip">
              {{ schedule?.['emailBCCCount'] }} Participants
              </strong>
            </div>

            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Password</span>
              <span class="col-sm-1">:</span>
              <div class="col-sm-5 d-flex align-items-center">
                <ng-container *ngIf="schedule?.['password']; else noPassword">
                  <strong class="pl-0">{{ schedule?.['password'] }}</strong>
                  
                  <!-- Conditional Copy Button -->
                  <div class="copy-container position-relative" *ngIf="schedule?.['passwordType'] === 'custom'">
                    <img
                      src="\assets\Icons\Copy.svg"
                      alt="Copy"
                      (click)="copyToClipboard(schedule?.['password'], schedule)"
                      style="cursor: pointer; width: 24px; height: 18px;">
                    <span [ngClass]="{'copy-feedback': true, 'show': schedule.copySuccess}">Copied!</span>
                  </div>                
                </ng-container>
                
                <ng-template #noPassword>
                  <strong class="pl-0 text-muted">Dynamic password</strong>
                </ng-template>
              </div>         
            </div>

            <div class="row d-flex align-items-center" title="Status">
              <span class="col-sm-6 text-muted">Notify Blank Attachment&nbsp;</span>
              <span class="col-sm-1">:</span>
              <app-checkbox-toggle class="col-sm-5"
                [checked]="schedule.notifyBlacnkAttahcment"
                (click)="onToggleClick($event)"
                (onClick)="onToggleNotifyBlank($event, schedule.jobID)"
              ></app-checkbox-toggle>
            </div>
            <div class="row d-flex align-items-center" title="Status">
              <span class="col-sm-6 text-muted">Email when data is available  &nbsp;</span>
              <span class="col-sm-1">:</span>
              <app-checkbox-toggle class="col-sm-5"
                [checked]="schedule.notifyOnlyOnData"
                (click)="onToggleClick($event)"
                (onClick)="onToggle($event, schedule.jobID)"
              ></app-checkbox-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Weekly Schedules -->


  <!-- Monthly Schedules -->
  <div *ngIf="schedulesMonthly.length > 0" style="padding-left: 20px; padding-top: 10px; padding-right: 20px;">
    <div class="row">
     <div class="col-sm-6 mb-3" style="font-size: 16px;"> Monthly Schedules </div>
    </div>
    <div class="row">
      <div *ngFor="let schedule of schedulesMonthly; let i = index" class="col-sm-6 mb-3">
        <div class="card" style="width: 100%;">
          <div class="card-header">
            {{ getTitle(schedule.cron) }}
            <button *ngIf="userDetials.isPublish" 
                    (click)="Previewreport(schedule.jobID)" class="preview-btn">
              Preview
            </button>
          </div> 
          <div class="card-body">
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email To</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
                [matTooltip]="getFormattedEmailTooltip(schedule?.emailTo)" 
                matTooltipPosition="above" 
                matTooltipClass="multiline-tooltip">
                {{ schedule?.['emailToCount'] }} Participants
              </strong>
            </div>
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email CC</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
              [matTooltip]="getFormattedEmailTooltip(schedule?.emailCC)" 
              matTooltipPosition="above" 
              matTooltipClass="multiline-tooltip">
              {{ schedule?.['emailCCCount'] }} Participants
              </strong>
            </div>
            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Email BCC</span>
              <span class="col-sm-1">:</span>
              <strong class="col-sm-5 pl-0"
              [matTooltip]="getFormattedEmailTooltip(schedule?.emailBCC)" 
              matTooltipPosition="above" 
              matTooltipClass="multiline-tooltip">
              {{ schedule?.['emailBCCCount'] }} Participants
              </strong>
            </div>

            <div class="row mb-1">
              <span class="col-sm-6 _600 text-muted">Password</span>
              <span class="col-sm-1">:</span>
              <div class="col-sm-5 d-flex align-items-center">
                <ng-container *ngIf="schedule?.['password']; else noPassword">
                  <strong class="pl-0">{{ schedule?.['password'] }}</strong>
                  
                  <!-- Conditional Copy Button -->
                  <div class="copy-container position-relative" *ngIf="schedule?.['passwordType'] === 'custom'">
                    <img
                      src="\assets\Icons\Copy.svg"
                      alt="Copy"
                      (click)="copyToClipboard(schedule?.['password'], schedule)"
                      style="cursor: pointer; width: 24px; height: 18px;">
                    <span [ngClass]="{'copy-feedback': true, 'show': schedule.copySuccess}">Copied!</span>
                  </div>                
                </ng-container>
                
                <ng-template #noPassword>
                  <strong class="pl-0 text-muted">Dynamic password</strong>
                </ng-template>
              </div>         
            </div>

            <div class="row d-flex align-items-center" title="Status">
              <span class="col-sm-6 text-muted">Notify Blank Attachment&nbsp;</span>
              <span class="col-sm-1">:</span>
              <app-checkbox-toggle class="col-sm-5"
                [checked]="schedule.notifyBlacnkAttahcment"
                (click)="onToggleClick($event)"
                (onClick)="onToggleNotifyBlank($event, schedule.jobID)"
              ></app-checkbox-toggle>
            </div>
            <div class="row d-flex align-items-center" title="Status">
              <span class="col-sm-6 text-muted">Email when data is available  &nbsp;</span>
              <span class="col-sm-1">:</span>
              <app-checkbox-toggle class="col-sm-5"
                [checked]="schedule.notifyOnlyOnData"
                (click)="onToggleClick($event)"
                (onClick)="onToggle($event, schedule.jobID)"
              ></app-checkbox-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
  <!-- End of Monthly Schedules -->

  <ng-template #noSchedules>
    <div class="row" style="padding: 10px;">
      <h4 style="text-align: center;margin-top: 1vh;">No Schedules!!</h4>
    </div>
  </ng-template>




