import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { AxiosResponse } from 'axios';
import { FilterTypeDefintion, RBOrganizationReportsDefinition, RBUserscheduledReportMaster } from 'src/app/helpers/app-models';
import { MainAppUtils } from 'src/app/helpers/main-app-utils';
import { MsgboxService } from 'src/app/helpers/msgbox.service';
import { RbDataService } from 'src/app/services/rb-data.service';

@Component({
  selector: 'app-criteria-configuration',
  templateUrl: './criteria-configuration.component.html',
  styleUrls: ['./criteria-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CriteriaConfigurationComponent {

  constructor(
    private rdb: RbDataService,
    private msgbox: MsgboxService,
  ){}

  _showCronEditor = false;
  _cronExpressionNewValue = '';
  _utils = new MainAppUtils();
  isEmail=false;

  @Input() index = 0;
  @Input() schedule: RBUserscheduledReportMaster = new RBUserscheduledReportMaster();
  
 // @Input() selectedReport: RBOrganizationReportsDefinition = new RBOrganizationReportsDefinition();
  
  _filtersWithData = new Array<FilterTypeDefintion>();
  @Input() set dataFilters (val:Array<FilterTypeDefintion>){ 
    this._filtersWithData = this._utils.clone(val);
    this.populateUserValuesToFilters();
  }

  @Output() onDelete = new EventEmitter<number>();

  //@Output() onFilterValuesChange = new EventEmitter<KeyValueItem>();
  

  ngOnInit() {
    //this.populateUserValuesToFilters();
    console.log("schedule.cronExpression",this.schedule.cronExpression)
    this._cronExpressionNewValue = this.schedule.cronExpression;
    //this.populateUserValuesToFilters();
    //if (this.schedule && this.schedule.reportFilterCriteria)
      //this._filterValues = this.schedule.reportFilterCriteria;
  }

  populateUserValuesToFilters(){
    if(!this.schedule.reportFilterCriteria)
      this.schedule.reportFilterCriteria = {"payload":{}}; 

    console.log(this._filtersWithData);
    console.log(this.schedule.reportFilterCriteria.payload); 

    if(this.schedule.reportFilterCriteria && this._filtersWithData){
      for(let i = 0; i < this._filtersWithData.length; i++){
        console.log(this._filtersWithData[i].filterType);
        if(this._filtersWithData[i].filterType.toLowerCase() == "ServiceType".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["servicetype"]; //"Global";
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "CustomServicetype".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["customservicetype"]; //"Global";
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Laboratory".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["laboratory"]; //"ABL Mutation" 
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "OrderingOrganization".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["orderingorganization"]; //"ABL Mutation" 
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "CaseType".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["casetype"]; //"ABL Mutation"           
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Flags".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["flags"]; //"ABL Mutation"           
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Diagnostician".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["diagnostician"]; //"ABL Mutation"           
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Pathologist".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["pathologist"]; //"ABL Mutation"           
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Percentage".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["percentage"]; //"ABL Mutation"           
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Algorithmtype".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["algorithmtype"]; //"ABL Mutation"           
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Screeningtechnologist".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["screeningtechnologist"]; //"ABL Mutation"           
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Cytotechnologist".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["cytotechnologist"]; //"ABL Mutation"           
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Salesexecutive".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["salesexecutive"]; //"ABL Mutation"          
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "CaseTypeCategory".toLowerCase()){
          this._filtersWithData[i].userValue = this.schedule.reportFilterCriteria.payload["casetypecategory"]; //"ABL Mutation"
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "ReceivedDate".toLowerCase()){
          this._filtersWithData[i].userValue = JSON.stringify(this.schedule.reportFilterCriteria.payload["receiveddate"]);
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "CollectionDate".toLowerCase()){
          this._filtersWithData[i].userValue = JSON.stringify(this.schedule.reportFilterCriteria.payload["collectiondate"]);
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "SignoutDate".toLowerCase()){
          this._filtersWithData[i].userValue = JSON.stringify(this.schedule.reportFilterCriteria.payload["signoutdate"]);
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Ordereddate".toLowerCase()){
          this._filtersWithData[i].userValue = JSON.stringify(this.schedule.reportFilterCriteria.payload["ordereddate"]);
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Deletedsequestereddate".toLowerCase()){
          this._filtersWithData[i].userValue = JSON.stringify(this.schedule.reportFilterCriteria.payload["deletedsequestereddate"]);
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Transactiondate".toLowerCase()){
          this._filtersWithData[i].userValue = JSON.stringify(this.schedule.reportFilterCriteria.payload["transactiondate"]);
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Received_datetime".toLowerCase()){
          this._filtersWithData[i].userValue = JSON.stringify(this.schedule.reportFilterCriteria.payload["received_datetime"]);
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Collection_datetime".toLowerCase()){
          this._filtersWithData[i].userValue = JSON.stringify(this.schedule.reportFilterCriteria.payload["collection_datetime"]);
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Signout_datetime".toLowerCase()){
          this._filtersWithData[i].userValue = JSON.stringify(this.schedule.reportFilterCriteria.payload["signout_datetime"]);
        }
        else if(this._filtersWithData[i].filterType.toLowerCase() == "Distribution_datetime".toLowerCase()){
          this._filtersWithData[i].userValue = JSON.stringify(this.schedule.reportFilterCriteria.payload["distribution_datetime"]);
        }
        else
        {
          this._filtersWithData[i].userValue = 
         this.schedule.reportFilterCriteria.payload[this._filtersWithData[i].filterType];
        } 
      }
    }

    //when user deletes from filter columns, reportCriteria should be cleaned too
    // let payloadKeys = Object.keys(this.schedule.reportFilterCriteria.payload);
    // let foundIdex = -1;
    // for(let i =0; i < payloadKeys.length; i++){
    //   foundIdex = this._filtersWithData.findIndex(x=>x.filterType == payloadKeys[i]);
    //   console.log("index => "+foundIdex);
    //   if(foundIdex == -1)
    //     delete this.schedule.reportFilterCriteria.payload[payloadKeys[i]];
    // }
  }

  onReportActiveToggled(active: boolean ,jobId: number) {
    this.schedule.isActive = active;
    this.OnActive(jobId,active);
  }

  OnActive(JobId: number,isActive: boolean): void{
    this.rdb.UpdateScheduleStatus(JobId,isActive).then(
      (response: AxiosResponse<any>) => {
        if (response.status === 200) {
          if (isActive) {
            this.msgbox.success("Schedule Activated Successfully", "");
          } else {
            this.msgbox.warning("Schedule Deactivated Successfully!", "");
          }
        }
        else{
          console.log('Report status update failed');
        }
      },
    )
  }


  onReceivedCronExpressionOkEvent() {
    this.schedule.cronExpression = this._cronExpressionNewValue;
    this.toggleCronPopup();
  }

  onReceivedFilterValues(jsonObj: any) {
    console.log(JSON.stringify(jsonObj,null,3));
    //this.schedule.reportFilterCriteria = jsonObj;
  }

  toggleCronPopup() {
    this._showCronEditor = !this._showCronEditor;
  }

  selectedCronValue: string = '';

  handleCronValueChange(cronValue: string) {
    this.selectedCronValue = cronValue;
    console.log('Cron Expression received from child:', this.selectedCronValue);
  }

  onCronExpressionChange(newCronExpression: string) {
    this.schedule.cronExpression = newCronExpression;
    console.log(this.schedule.cronExpression);
    console.log('this.schedule.cronExpression');
  }

}
