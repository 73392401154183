import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { UserDetails } from './app-models';


@Injectable({
  providedIn: 'root'
})
export class BrowserStorageManagerService {

  private DOMAIN_KEY = 'domainKey';
  private USER_INFO = 'userInfo';
  private userInfoDataCache = new UserDetails();
  private userDomainKey : string = '';

  constructor(
    private cookieSvc: CookieService
  ) { }

  GetEMMCookiesForHeader() {
    return {
      "Content-Type": 'application/json',
      "x-va-senderagent-id": this.cookieSvc.get('senderAgentID'),
      "x-va-hash": this.cookieSvc.get('hashKey'),
      "x-va-transaction-id": this.cookieSvc.get('transactionID'),
      "Authorization": this.cookieSvc.get('SSOAppTokenString'),
      "x-va-tokenurl": this.cookieSvc.get('tokenURL')
    }
  }

  GetCookiesForHeaderForSVC() {
    return {
      "Content-Type": 'application/json',
      "x-va-senderagent-id": this.cookieSvc.get('senderAgentID'),
      "x-va-hash": this.cookieSvc.get('hashKey'),
      "x-va-transaction-id": this.cookieSvc.get('transactionID'),
      "Authorization": this.cookieSvc.get('SSOAppTokenString'),
      "x-va-tokenurl": this.cookieSvc.get('tokenURL'),
      "x-va-ip":this.cookieSvc.get('ipAddress')
    }
  }

  GetEMMNonHeaderValues() {
    return {
      "ip": this.cookieSvc.get('ipAddress'),
      "authUrl":this.cookieSvc.get('tokenURL')
    };
  } 
  
  GetAPIHeaders():{}{ 
      return { Authorization : localStorage.getItem('Authorization') }; 
  } 

  GetUserInfo(){
    return this.userInfoDataCache;
  }
 

  ClearCookies() {
    this.cookieSvc.deleteAll();
  }

}
